import * as Updates from 'expo-updates'
import { useEffect } from 'react';

export default function useCheckAppUpdate(){
  // OTA_AUTOMATIC_RELOAD v1
  async function checkAppReload() {
    try {
      const update = await Updates.checkForUpdateAsync();

      if (update.isAvailable) {
        await Updates.fetchUpdateAsync();
        // ... notify user of update ...
        await Updates.reloadAsync()
      }
    } catch (e) {
      // handle or log error
    }
  }

  useEffect(() => {
    checkAppReload()
  }, [])
  ///
}