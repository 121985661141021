import * as React from 'react';
import { View } from 'react-native'

import api from "../../../utils/api"
import tw from '../../../styles/tailwind';
import Text from '../../../components/Text';
import Icon from '../../../components/Icon';
import AdminLayout from '../../../navigation/AdminLayout';



export default function IncidencesScreen({ navigation }) {

  return (
    <AdminLayout>
      <Text style={tw`mb-2 text-3xl`}>Incidencias</Text>

      <pre>
- 🏆 Listado de incidencias{"\n"}
{"\t"}- 🏆 Pendientes{"\n"}
{"\t"}{"\t"}- 🏆Nombre / Área / Tipo Incidencia / Periodo / Comentarios / Detalle / Archivo{"\n"}
{"\t"}{"\t"}- Acciones Masivas: Aprobar / Rechazar{"\n"}
{"\t"}{"\t"}- 🏆 Editar incidencia -> abrir modal edición{"\n"}
{"\t"}{"\t"}- 🏆 Aprobar -> Modal aprobración (Mensaje opcional){"\n"}
{"\t"}{"\t"}- 🏆 Rechazar -> Modal rechazo (Razón rechazo){"\n"}
{"\t"}{"\t"}- 🏆 Modal Detalle (lateral){"\n"}
{"\t"}{"\t"}- 🏆 Modal Edición*{"\n"}
{"\t"}{"\t"}- 🏆 Modal Aprobación{"\n"}
{"\t"}{"\t"}- 🏆 Modal Rechazo{"\n"}
{"\t"}{"\t"}? Tipos visibles acá:{"\n"}
{"\t"}{"\t"}{"\t"}- Falta{"\n"}
{"\t"}{"\t"}{"\t"}- Retardo{"\n"}
{"\t"}{"\t"}{"\t"}- Sanción{"\n"}
{"\t"}{"\t"}{"\t"}- Horas extras{"\n"}
{"\t"}{"\t"}{"\t"}- Bono{"\n"}
{"\t"}{"\t"}{"\t"}- Incapacidad{"\n"}
{"\t"}{"\t"}{"\t"}- Vacaciones{"\n"}
{"\t"}- 🏆 Resueltas{"\n"}
{"\t"}{"\t"}- 🏆Nombre / Área / Tipo Incidencia / Estado / Periodo / Fecha creación / Fecha Aprobación / Detalle / Revisado por / Comentarios / Archivo{"\n"}
{"\t"}{"\t"}- 🏆 Descargar excel{"\n"}
{"\t"}{"\t"}- 🏆 Filtro periodo fecha{"\n"}
{"\t"}{"\t"}- 🏆 Ver Detalle -> Abre modal Detalle{"\n"}
{"\t"}{"\t"}- 🏆 Descargar excel{"\n"}
- 🏆🏆 Carga masiva incidencias{"\n"}
{"\t"}{"\n"}
- 🏆 Pantalla Configuración de incidencias{"\n"}
{"\t"}- Crear Tipo de Incidencia{"\n"}
{"\t"}- Modal Nuevo / Edición de tipo{"\n"}
{"\t"}- Flujos de aprobación?{"\n"}
- 🏆 Notificaciones de incidencias{"\n"}
- Crear incidencia        
      </pre>
    </AdminLayout>
  );
}
