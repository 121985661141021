import * as React from 'react';
import { View } from 'react-native'

import api from "../../../utils/api"
import tw from '../../../styles/tailwind';
import Text from '../../../components/Text';
import Icon from '../../../components/Icon';
import AdminLayout from '../../../navigation/AdminLayout';



export default function SettingsScreen({ navigation }) {

  return (
    <AdminLayout>
      <Text style={tw`mb-2 text-3xl`}>Configuración</Text>

      <pre>
  - Configuración de empresa{"\n"}
  - Configuración de sucursales{"\n"}
  - Razones sociales{"\n"}
  - Configuración de puestos{"\n"}
  - Configuración de áreas{"\n"}
  - Configuración de usuarios y roles
      </pre>
    </AdminLayout>
  );
}
