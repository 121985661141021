import React from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native'

import tw from '../styles/tailwind';
import Text from '../components/Text';
import Icon from '../components/Icon';
import { useLinkTo, useRoute } from '@react-navigation/native';
import { actions } from "../store/Reducers/auth";
import { useDispatch } from 'react-redux';

import { Layout, Nav, Button, Breadcrumb, Skeleton, Avatar } from '@douyinfe/semi-ui';
import { IconCalendarClock, IconBell, IconHelpCircle, IconUserGroup, IconHome, IconHistogram, IconLive, IconSetting } from '@douyinfe/semi-icons';
import { LocaleProvider } from '@douyinfe/semi-ui';
import es from '@douyinfe/semi-ui/lib/es/locale/source/es';


// function AdminMenu() {
//   const route = useRoute()
//   const linkTo = useLinkTo()
//   const dispatch = useDispatch()

//   function renderItem(label, path, iconName=''){
//     const selected = route.path?.startsWith(path)

//     return (
//       <TouchableOpacity
//         style={tw.style(`row justify-between py-4 px-3`, selected && `bg-blue-500 rounded-xl`)}
//         onPress={() => linkTo(path)}
//       >
//         <View style={tw`row`}>
//           <Icon name={iconName} color={tw.color(selected ? 'white' : 'gray-500')} size={17} />

//           <Text style={tw.style(`font-bold ml-2`, selected && `text-white`)}>{label}</Text>
//         </View>

//         <Icon name='chevron-right' color={tw.color(selected ? 'white' : 'gray-400')} />
//       </TouchableOpacity>
//     )
//   }

//   return (
//     <View style={tw`bg-white rounded-xl shadow-lg py-4 w-75 px-3`}>
//       {renderItem("Usuarios", "/admin/usuarios", 'administracion')}
//       {renderItem("Zonas", "/admin/zonas", 'zonas')}
//       {renderItem("Marcas", "/admin/marcas", 'marcas')}
//       {renderItem("Aseguradoras", "/admin/aseguradoras", 'marcas')}
//       {/* <Text onPress={() => dispatch(actions.logout())}>Cerrarserrión</Text> */}
//     </View>
//   )
// }


// export default function AdminLayout({ children }) {
//   return (
//     <MainLayout>
//       <View style={tw`row justify-center items-start gap-x-8 flex-1`}>
//         <AdminMenu />

//         {children}
//       </View>
//     </MainLayout>
//   );
// }







function AdminHeader({title}){
  const { Header } = Layout;
  return (
    <Header>
      <div >
        <Nav mode='horizontal' defaultSelectedKeys={['Home']} style={{ backgroundColor: '#EDF3F8' }}>
          <Nav.Header>
            {/* <IconSemiLogo style={{ fontSize: 36 }} /> */}
            {
              title &&
                <Text style={tw`text-xl`}>{title}</Text>
            }
          </Nav.Header>
          {/* 
          <span
            style={{
              color: 'var(--semi-color-text-2)'
            }}
          >
            <span
              style={{
                marginRight: '24px',
                color: 'var(--semi-color-text-0)',
                fontWeight: '600',
              }}>Semi Design</span>
            <span style={{ marginRight: '24px' }}>Semi Theme</span>
            <span>Semi Blocks</span>
          </span> */}
          <Nav.Footer>
            <Button
              theme="borderless"
              icon={<IconBell size="large" />}
              style={{
                color: 'var(--semi-color-text-2)',
                marginRight: '12px',
              }}
            />
            <Button
              theme="borderless"
              icon={<IconHelpCircle size="large" />}
              style={{
                color: 'var(--semi-color-text-2)',
                marginRight: '12px',
              }}
            />
            <Avatar color='orange' size='small'>YJ</Avatar>
          </Nav.Footer>
        </Nav>
      </div>
    </Header>
  )
}


function Module({ children, style }: { children: any, style?: object }) {
  return (
    <View style={tw.style(`bg-white rounded-xl px-5 py-5`, { boxShadow: '0 0 15px 1px #cbcbcb' })}>
      {children}
    </View>
  )
}



export default function AdminLayout({ children, title }) {
  const { Header, Footer, Sider, Content } = Layout;
  const linkTo = useLinkTo()

  const menuItems = [
    { itemKey: 'Inicio', path: "/", text: 'Inicio', icon: <IconHome size="large" /> },
    { itemKey: 'Colaboradores', path: "/colaboradores", text: 'Colaboradores', icon: <IconUserGroup size="large" /> },
    { itemKey: 'Checador', path: "/checador", text: 'Checador', icon: <IconCalendarClock size="large" /> },
    { itemKey: 'Incidencias', path: "/incidencias", text: 'Incidencias', icon: <IconCalendarClock size="large" /> },
    { itemKey: 'Configuración', path: "/configuracion", text: 'Configuración', icon: <IconSetting size="large" /> },
  ]

  function onMenuSelected(itemKey){
    const menu = menuItems.find(menu => itemKey === menu.itemKey)

    linkTo(menu.path)
  }

  return (
    <LocaleProvider locale={es}>
      <Layout
        style={{
          // border: '1px solid var(--semi-color-border)' 
          height: "100%"
        }}>
        <Sider
          // className="semi-always-dark"
          // , backgroundColor: '#2A3247'
          style={{
            // backgroundColor: 'var(--semi-color-bg-1)',

          }}>
          <Nav
            style={{ maxWidth: 220, height: '100%', backgroundColor: '#fff', border:0 }}
            defaultSelectedKeys={['Inicio']}
            onClick={({ itemKey }) => onMenuSelected(itemKey)}
            items={menuItems}
            footer={{
              collapseButton: true,
              collapseText: (collapsed) => !collapsed ? "Colapsar panel" : "Abrir panel",
            }}
          />
        </Sider>

        <Layout style={{overflowY:'hidden'}}>
          <AdminHeader title={title}/>


          <Content
            style={{
              padding: '24px',
              backgroundColor: '#EDF3F8',
              overflowY: 'auto'
            }}
          >
            { children }
            {/* <Module> */}
            {/* </Module> */}
            {/* <Breadcrumb
              style={{
                marginBottom: '24px'
              }}
              routes={['Home', 'Page Section', 'Ppage Ssection', 'Detail']} />
            <div
              style={{
                borderRadius: '10px',
                border: '1px solid var(--semi-color-border)',
                height: '376px',
                padding: '32px'
              }}
            >
              <Skeleton placeholder={(<Skeleton.Paragraph rows={2} />)} loading={true}>
                <p>Hi, Bytedance dance dance.</p>
                <p>Hi, Bytedance dance dance.</p>
              </Skeleton>
            </div> */}


          </Content>
        </Layout>
      </Layout>
    </LocaleProvider>
  );
};
