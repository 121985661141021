import React, {useState, useEffect, useMemo} from 'react';
import { View } from 'react-native'

import api from "../../../../utils/api"
import tw from '../../../../styles/tailwind';
import Text from '../../../../components/Text';
import Icon from '../../../../components/Icon';
import Modal from '../../../../components/Modal';

import { Form, Select, Checkbox, Radio, Button } from '@douyinfe/semi-ui'; 
import useFormSubmit from '../../../../hooks/useFormSubmit';


export default function EmployeeLayoffForm({ onRequestClose, employeeId,  onEmployeeDeleted }) {
  const [formApi, setFormApi] = useState<any>(null);


  const { submitForm, isSubmitting } = useFormSubmit({
    onSuccess: onEmployeeDeleted,
    method: "post",
    onError: (error) => { },
    successToastMessage: "Colaborador dado de baja",
    endpoint: `/employees/${employeeId}/layoff`,
  })

  function onFormSubmit() {
    formApi?.validate()
      .then((values) => {
        console.log("validated!", values)
        submitForm(values)
      })
      .catch((errors) => {
        console.log("yeerrorah", errors)
      });
  }


  return (
    <Modal
      title={"Baja de colaborador"}
      onRequestClose={onRequestClose}
      onOk={onFormSubmit}
      okText={'Dar de baja'}
      okButtonProps={{ loading: isSubmitting, type: 'warning' }}
    >
      <Form
        labelWidth={200}
        style={{
          padding: '10px',
        }}
        
        getFormApi={setFormApi}
        // onSubmit={data => console.log("yeah", data) }
      >
        <Form.RadioGroup
          field="reason"
          label='Motivo de la baja'
          direction="vertical"
          rules={[
            { required: true, message: 'Requerido' },
          ]}
        >
          <Radio value={"separacion_voluntaria"}>Separación voluntaria</Radio>
          <Radio value={"resicion_contrato"}>Resición de contrato</Radio>
          <Radio value={"otro"}>Otro</Radio>
        </Form.RadioGroup>

        <Form.TextArea
          field="reason_description"
          noLabel
          placeholder='Comentarios / Detalle de la baja'
          style={tw`w-80`}
        />

      </Form>
    </Modal>
  );
}
