import React, { useState, useEffect } from 'react';
import { View, ScrollView, TouchableOpacity, Alert } from "react-native"

import tw from '../../styles/tailwind';
import Text from '../../components/Text';
import { Ionicons } from "@expo/vector-icons"
import * as Linking from 'expo-linking';

import { useForm } from "react-hook-form";
import Input from '../../components/Forms/Input';
import Button from '../../components/Forms/Button';
import { SafeAreaView } from 'react-native-safe-area-context';



function TicketForm(){
  const { control, handleSubmit, formState, formState: { errors }, setError, setValue, reset } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ticketSent, setTicketSent] = useState(false);


  function sendSupportTicket(data) {
    setIsSubmitting(true)

    setTimeout(() => {
      setTicketSent(true)
    }, 1000)
  }

  return (
    <View>
      {
        !ticketSent ?
          <View style={tw`mt-4 border-t border-gray-300 pt-8`}>
            <Text style={tw`text-gray-500 mb-5`}>O también puedes escribirnos en este formulario:</Text>

            <Input
              name="name"
              control={control}
              rules={{ required: "Nombre Requerido" }}
              label="Tu Nombre"
              style={{ container: tw`flex-1 mt-5`, input: tw`text-gray-400`, label: tw`bg-transparent font-normal ml-1 -mt-5 text-gray-400` }}
            />

            <Input
              name="email"
              control={control}
              keyboardType={"email-address"}
              label="Correo electrónico"
              rules={{ pattern: { value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, message: "Dirección de correo no válida" } }}
              style={{ container: tw`flex-1 mt-5`, input: tw`text-gray-400`, label: tw`bg-transparent font-normal ml-1 -mt-5 text-gray-400` }}
            />

            <Input
              name="message"
              control={control}
              label="Tu mensaje"
              multiline
              numberOfLines={3}
              style={{ container: tw`flex-1 mt-5`, input: tw`text-gray-400`, label: tw`bg-transparent font-normal ml-1 -mt-5 text-gray-400` }}
            />

            <Button
              label={!isSubmitting ? "Enviar" : "Enviando..."}
              disabled={isSubmitting}
              onPress={handleSubmit(sendSupportTicket)}
              style={{ container: tw`mb-8 mt-5` }}
            />
          </View>
          :
          <Text style={tw`text-gray-400 mt-10 text-lg`}>Gracias por tu mensaje. Estaremos revisando tu mensaje y te contactaremos a la brevedad posible.</Text>
      }
    </View>
  )
}


function DeleteAccountForm(){
  const { control, handleSubmit, formState, formState: { errors }, setError, setValue, reset } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deletionSent, setDeletionSent] = useState(false);


  function sendAccountDeletion(data) {
    setIsSubmitting(true)

    setTimeout(() => {
      setDeletionSent(true)
    }, 1000)
  }

  function confirmDeletion(){
    Alert.alert('¿Eliminar tu cuenta?', 'Esta acción no puede ser revertida', [
      {
        text: 'Cancelar',
        onPress: () => { },
        style: 'cancel',
      },
      { text: 'Eliminar', onPress: handleSubmit(sendAccountDeletion)},
    ]);
  }

  return (
    <View>
      <Text style={tw`text-gray-600 text-lg text-center mb-4`}>Eliminar mi cuenta</Text>

      {
        ! deletionSent &&
          <>
            <Text style={tw`text-gray-500 mb-3`}>Si deseas eliminar tu cuenta, te podemos ayudar en el proceso. Al eliminar tu cuenta borraremos todos tus datos personales así como la información relacionada a tu cuenta</Text>
            <Text style={tw`text-gray-500`}>Este proceso puede tardar de 1 a 2 días hábiles. Esta acción no se puede revertir</Text>

            <Input
              name="message"
              control={control}
              label="Motivo de cancelación (opcional)"
              multiline
              numberOfLines={3}
              style={{ container: tw`flex-1 mt-10`, input: tw`text-gray-400`, label: tw`bg-transparent font-normal ml-1 -mt-5 text-gray-400` }}
            />

            <Button
              label={!isSubmitting ? "Eliminar mi cuenta" : "Enviando..."}
              disabled={isSubmitting}
              onPress={confirmDeletion}
              style={{ container: tw`mb-8 mt-5` }}
            />
          </>
      }

      {
        deletionSent &&
          <Text style={tw`text-center`}>Hemos recibido tu solicitud, el proceso de eliminación puede tardar de 1 a 2 días hábiles.</Text>
      }
    </View>
  )
}


export default function SupportScreen({ }) {
  const [showDeleteAccountForm, setShowDeleteAccountForm] = useState(false);

  return (
    <ScrollView contentContainerStyle={tw`px-3 py-8`}>
      <Text style={tw`text-gray-500`}>Estamos para ayudarte con cualquier duda o problema que tengas</Text>

      <TouchableOpacity
        onPress={() => {
          const waUrl = 'https://wa.me/5217712162398'

          Linking.openURL(waUrl);
        }}
        style={tw`row items-center justify-center px-3 py-3 border border-green-500/80 rounded-lg mt-5 mb-8`}
      >
        <Text style={tw`text-gray-500 mr-3`}>Escríbenos en Whatsapp</Text>
        <Ionicons name='logo-whatsapp' size={20} color={"green"} />
      </TouchableOpacity>


      {
        showDeleteAccountForm ?
          <DeleteAccountForm />
        :
          <Text style={tw`text-red-400`} onPress={() => setShowDeleteAccountForm(true)}>Eliminar mi cuenta</Text>
      }

      {
        ! showDeleteAccountForm &&
          <TicketForm />
      }

      
    </ScrollView>
  )
}