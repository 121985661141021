import { Image } from 'expo-image';
import React, { useState } from 'react';
import { Pressable, TouchableOpacity, View, Platform } from 'react-native'
import tw from '../styles/tailwind';
import Text from './Text';
import Icon from './Icon';

import { Modal as SemiModal, Button, List } from '@douyinfe/semi-ui';

export default function Modal({
  children,
  onRequestClose,
  title,
  backgroundClosesModal = true,
  style={},
  onOk,
  ...props
}) {
  return (
    <SemiModal
      visible
      size='medium'
      style={{ width: 'inherit' }}
      header={null}
      // footer={undefined}
      bodyStyle={tw`pt-10`}
      onCancel={onRequestClose}
      onOk={onOk}
      maskClosable={backgroundClosesModal}
      {
        ...props
      }
    >
      <style>{".semi-modal-wrap{display:flex;align-items:center;justify-content:center;}"}</style>
      
      {/* Titulo / boton cerrar */}
      <View style={tw`items-center bg-gray-100 py-2 rounded-t-xl absolute top-0 left-0 right-0`}>
        <Text style={tw`text-base font-bold text-center`}>{title}</Text>

        <TouchableOpacity onPress={onRequestClose} style={tw`absolute right-3 top-[10px]`}>
          <Icon name='close' size={20} color={tw.color('red-500')} />
        </TouchableOpacity>
      </View>

      <View style={tw``}>
        {children}
      </View>
    </SemiModal>
  )
}