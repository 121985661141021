import React, {useState, useEffect, useMemo} from 'react';
import { TouchableOpacity, View } from 'react-native'

import api from "../../../utils/api"
import tw from '../../../styles/tailwind';
import Text from '../../../components/Text';
import Icon from '../../../components/Icon';
import AdminLayout from '../../../navigation/AdminLayout';
// import Button from '../../../components/Forms/Button';
import { Button, List, Popover, Switch } from '@douyinfe/semi-ui';

import { useQuery } from '@tanstack/react-query';
import { Avatar, Col, Row } from '@douyinfe/semi-ui';
import EmployeeLayoffForm from './Forms/EmployeeLayoffForm';

import EditPersonalInfoForm from './Forms/EditPersonalInfoForm';
import EditBankInfoForm from './Forms/EditBankInfoForm';
import EditPayrollInfoForm from './Forms/EditPayrollInfoForm';
import EditEmergencyContactInfoForm from './Forms/EditEmergencyContactInfoForm';
import NewEmployeeForm from './Forms/NewEmployeeForm';
import useFormSubmit from '../../../hooks/useFormSubmit';


function DataGrid({ data, style }: { data: Array<Array<string>>, style?:object}){
  return (
    <Row gutter={16} style={tw.style(style)}>
      {/* {
        data.map((cellInfo, i) =>
          <Col span={6} key={i}>
            <View style={tw`border-b border-gray-200 py-2`}>
              <View style={tw``}>
                <Text style={tw`text-gray-400`}>{cellInfo[0]}</Text>
              </View>

              <View style={tw``}>
                <Text style={tw`text-gray-700 font-500`}>{cellInfo[1] ?? "-"}</Text>
              </View>
            </View>
          </Col>
        )
      } */}
      <List
        dataSource={data}
        layout="horizontal"
        grid={{
          gutter: 12,
          span: 6,
        }}
        renderItem={cellInfo => (
          <List.Item
            align='stretch'
            style={{
              height: '100%',
              borderBottom: '1px solid',
              borderColor: tw.color('gray-200'),
              padding: '10px 8px',
              marginLeft: '-6px',
              marginRight: '-6px',
              alignContent: 'start',
            }}
            main={
              <View>
                <View style={tw``}>
                  <Text style={tw`text-gray-400 text-sm`}>{cellInfo[0]}</Text>
                </View>

                <View style={tw``}>
                  <Text style={tw`text-gray-700 font-500`}>{cellInfo[1] ?? "-"}</Text>
                </View>
              </View>
            }
          />
        )}
      />
    </Row>
  )
}


function DataBlock({ title, data, employeeId, editForm, editFormData, onInfoEdited, style={} }){
  const [showEditBlockDataModal, setShowEditBlockDataModal] = useState(false);

  return (
    <View style={tw.style(`bg-gray-100 rounded-lg px-4 py-4`, style)}>
      <View style={tw`row border-b border-gray-200 pb-2 mb-1`}>
        <Text style={tw`text-gray-700 font-500`}>{title}</Text>

        <TouchableOpacity style={tw`ml-2`} onPress={() => setShowEditBlockDataModal(true)}>
          <Text style={tw`text-primary`}>Editar</Text>
        </TouchableOpacity>
      </View>

      <View style={tw`px-2`}>
        <DataGrid data={data} />
      </View>

      {
        showEditBlockDataModal &&
          React.createElement(editForm, {
            onRequestClose: () => setShowEditBlockDataModal(false),
            formData: editFormData,
            employeeId,
            onInfoEdited: () => {
              setShowEditBlockDataModal(false)
              onInfoEdited?.()
            },
          })
      }
    </View>
  )
}

function DocumentBlock({ title, data, style={} }){

  return (
    <View style={tw.style(`bg-gray-100 rounded-lg px-4 py-4`, style)}>
      <View style={tw`row border-b border-gray-200 pb-2 mb-1`}>
        <Text style={tw`text-gray-700 font-500`}>{title}</Text>
      </View>

      <DocumentGrid documents={data} />
    </View>
  )
}




function DocumentGrid({ documents, style }: { documents: Array<Array<string>>, style?: object }) {
  return (
    <Row gutter={16} style={tw.style(`gap-y-2`, style)}>
      {
        documents.map((documentCell, i) =>
          <Col span={6} key={i}>
            <DocumentCell label={documentCell[0]} />
          </Col>
        )
      }
    </Row>
  )
}


function DocumentCell({label}){
  return (
    <View style={tw.style(`flex h-32 w-28 rounded-lg border border-primary justify-end items-center px-2 mb-4 bg-white`, {cursor: 'pointer'})}>
      <Text style={tw`text-primary text-3xl mb-5`}>+</Text>
      <Text style={tw`text-xs leading-3 text-primary font-500 mb-1 text-center h-10`}>{label}</Text>
    </View>
  )
}


export default function EmployeesDetailScreen({ navigation, route }) {
  const {employeeId} = route.params

  const [showLayoffModal, setShowLayoffModal] = useState(false);
  const [showGeneralInfoFormModal, setShowGeneralInfoFormModal] = useState(false);

  const { data: employeeInfo, refetch: refetchEmployee } = useQuery({
    queryKey: ['employees', employeeId],
    queryFn: () => api.get(`/employees/${employeeId}`).then(({ data }) => data),
  })

  const { data: employeePrivateInfo, isLoading, refetch: refetchPrivateInfo } = useQuery({
    queryKey: ['employees.private-info', employeeId],
    queryFn: () => api.get(`/employees/${employeeId}/private-info`).then(({ data }) => data),
  })

  const { submitForm, isSubmitting : isChangingActive } = useFormSubmit({
    onSuccess: () => {
      refetchEmployee()
    },
    method: "put",
    onError: (error) => { },
    successToastMessage: "Colaborador guardado",
    endpoint: `/employees/${employeeId}`,
  })

  function onActiveChange(value){
    submitForm({
      active: value,
    })
  }

  return (
    <AdminLayout title={
      <Text style={tw`text-sm`} onPress={() => navigation.navigate("Employees.Index")}>{"<"} Regresar al listado de colaboradores</Text>
    }>
      <style>{`.semi-col-6{width:20%}`}</style>
      {
        employeePrivateInfo &&
          <View style={tw``}>
            {/* Header Empleado */}
            <View style={tw`row justify-between`}>

              {/* Nombre y foto */}
              <View style={tw`row pl-2`}>
                <View style={tw`mr-4`}>
                  <Avatar>
                    <Text>PA</Text>
                  </Avatar>
                </View>

                <View style={tw``}>
                  <Text style={tw`text-2xl text-gray-600 font-500`}>{employeeInfo?.full_name}</Text>
                  <Text style={tw`text-gray-400`}>No. colaborador <Text style={tw`text-gray-600 font-500 ml-2`}>{employeeInfo?.employee_number ?? "-"}</Text></Text>
                </View>
              </View>


              {/* Estatus */}
              <View style={tw`row`}>
                <View style={tw`mr-5`}>
                  <Text style={tw`text-gray-700 font-500`}>Estatus</Text>
                  <Text style={tw.style(`font-500`, employeeInfo?.active ? "text-primary" : "text-gray-400")}>{employeeInfo?.active ? "Activo" : "Inactivo"}</Text>
                </View>

                <Switch checked={employeeInfo?.active} onChange={onActiveChange} loading={isChangingActive} />
              </View>

              {/* Acciones */}
              <View style={tw`row bg-white rounded-lg px-4 py-2`}>
                <Popover content={<View style={tw`p-2`}>Próximamente</View>}>
                  <Button><Text>Descargar expediente</Text></Button>
                </Popover>
                
                <Popover content={<View style={tw`p-2`}>Próximamente</View>}>
                  <Button><Text>Imprimir contrato</Text></Button>
                </Popover>
                
                <Button onClick={() => setShowLayoffModal(true)}><Text>Dar de baja</Text></Button>
              </View>
            </View>


            {/* Datos */}
            <View style={tw.style(`bg-white rounded-lg px-4 py-3 mt-5`, { boxShadow: '0 0 4px 0px #dddddd'})}>
              <View style={tw`row border-b border-gray-200 pb-2 mb-1 mt-2`}>
                <Text style={tw`text-gray-700 font-500`}>Datos Generales</Text>

                <TouchableOpacity style={tw`ml-2`} onPress={() => setShowGeneralInfoFormModal(true)}>
                  <Text style={tw`text-primary`}>Editar</Text>
                </TouchableOpacity>
              </View>

              <DataGrid
                style={tw`mb-4 px-4`}
                data={[
                  ['Correo electrónico', employeeInfo?.email],
                  ['Celular', employeeInfo?.phone_number],
                  ['Puesto', employeeInfo?.work_position?.name],
                  ['Unidad de Negocio', "--"],
                  ['Área', employeeInfo?.area?.name],
                  ['Jefe directo', employeeInfo?.boss?.full_name],
                  ['Oficina', employeeInfo?.location?.name],
                  ['Fecha de ingreso', employeeInfo?.work_start_date],
                  // ['Fecha de antigüedad?', employeeInfo?.email],
                ]}
              />


              <DataBlock
                style={tw`mb-4`}
                title={"Datos personales"}
                data={[
                  ['Estado Civil', employeePrivateInfo.general_info.civil_status],
                  ['Fecha de nacimiento', employeePrivateInfo.general_info.birthdate],
                  ['Lugar de nacimiento', employeePrivateInfo.general_info.birthplace],
                  ['Nacionalidad', employeePrivateInfo.general_info.nationality],
                  ['RFC', employeePrivateInfo.general_info.documents_rfc],
                  ['Género legal', employeePrivateInfo.general_info.gender_str],
                  ['Nivel de estudios', employeePrivateInfo.general_info.nivel_de_estudios],
                  ['INE', employeePrivateInfo.general_info.documents_clave_de_elector],
                  ['CURP', employeePrivateInfo.general_info.documents_curp],
                  ['NSS', employeePrivateInfo.general_info.documents_nss],
                ]}
                editForm={EditPersonalInfoForm}
                editFormData={employeePrivateInfo.general_info}
                employeeId={employeeId}
                onInfoEdited={() => refetchPrivateInfo()}
              />

              <DataBlock
                style={tw`mb-4`}
                title={"Datos bancarios"}
                data={[
                  ['Banco', employeePrivateInfo.payroll_info.salary_bank_name],
                  ['No. Tarjeta', employeePrivateInfo.payroll_info.salary_bank_card],
                  ['No. Cuenta', employeePrivateInfo.payroll_info.salary_bank_account],
                  ['CLABE', employeePrivateInfo.payroll_info.salary_bank_clabe],
                ]}
                editForm={EditBankInfoForm}
                editFormData={employeePrivateInfo.payroll_info}
                employeeId={employeeId}
                onInfoEdited={() => refetchPrivateInfo()}
              />

              <DataBlock
                style={tw`mb-4`}
                title={"Datos de nómina"}
                data={[
                  ['Sueldo neto', employeePrivateInfo.payroll_info.salary_net],
                  ['Sueldo bruto', employeePrivateInfo.payroll_info.salary_gross],
                  ['Último ajuste de sueldo', employeePrivateInfo.payroll_info.salary_adjustment_date],
                  ['Razón social', employeePrivateInfo.payroll_info.razon_social_name],
                  ['Tipo de contrato', employeePrivateInfo.payroll_info.payroll_contract_type_str],
                  ['Duración del contrato', employeePrivateInfo.payroll_info.salary_contract_duration],
                  ['Fecha de contrato', employeePrivateInfo.payroll_info.email],
                  // ['Tipo de régimen?', employeePrivateInfo.payroll_info.tax_regime_display],
                  ['Esquema de outsorcing', 
                      employeePrivateInfo.payroll_info.salary_is_outsourcing !== null ? 
                        employeePrivateInfo.payroll_info.salary_is_outsourcing ? "Sí" : "No" 
                      : "-"
                  ],
                ]}
                editForm={EditPayrollInfoForm}
                editFormData={employeePrivateInfo.payroll_info}
                employeeId={employeeId}
                onInfoEdited={() => refetchPrivateInfo()}
              />

              <DataBlock
                style={tw``}
                title={"Contacto de emergencia"}
                data={[
                  ['Nombre', employeePrivateInfo.general_info.contact_name],
                  ['Parentesco', employeePrivateInfo.general_info.contact_relationship],
                  ['Teléfono', employeePrivateInfo.general_info.contact_phone],
                  ['Correo electrónico', employeePrivateInfo.general_info.contact_email],
                  // ['Celular', employeePrivateInfo.general_info.contact_cellphone],
                ]}
                editForm={EditEmergencyContactInfoForm}
                editFormData={employeePrivateInfo.general_info}
                employeeId={employeeId}
                onInfoEdited={() => refetchPrivateInfo()}
              />
            </View>


            {/* Documentos */}
            <View style={tw.style(`bg-white rounded-lg px-4 py-3 mt-5`, { boxShadow: '0 0 4px 0px #dddddd'})}>
              <DocumentBlock
                style={tw`mb-4`}
                title={"Personales"}
                data={[
                  ['INE', 'ine'],
                  ['NSS', 'nss'],
                  ['CURP', 'curp'],
                  ['RFC', 'rfc'],
                  ['Pasaporte', 'passport'],
                  ['Comprobante de domicilio', 'proof_of_address'],
                  ['Acta de nacimiento', 'birth_certificate'],
                  ['Licencia de conducir', 'driving_license'],
                  ['Comprobante de cuenta bancaria', 'bank_account'],
                ]}
              />


              <DocumentBlock
                style={tw`mb-4`}
                title={"Laborales"}
                data={[
                  ['Currículum', 'curriculum_vitae'],
                  ['Contrato', 'work_contract'],
                  ['Carta de oferta', 'offer_letter'],
                  ['Solicitud de trabajo', 'job_application'],
                  ['Examenes psicométricos', 'psychometric_exams'],
                  ['Carta de recomendación', 'recommendation_letter'],
                  ['Constancia laboral', 'work_certificate'],
                  ['Aviso retención infonavit', 'infonavit_aviso'],
                  ['Fotografías', 'pictures'],
                ]}
              />

              <DocumentBlock
                style={tw``}
                title={"Académicos"}
                data={[
                  ['Certificado', 'academic_certificate'],
                  ['Comprobante de estudios', 'comprobante_estudios'],
                ]}
              />
            </View>
          </View>

          

      }


      {
        showLayoffModal &&
          <EmployeeLayoffForm
            onRequestClose={() => setShowLayoffModal(false)}
            employeeId={employeeId}
            onEmployeeDeleted={() => {
              setShowLayoffModal(false)
              navigation.navigate("Employees.Index")
            }}
          />
      }

      {
        showGeneralInfoFormModal &&
          <NewEmployeeForm
            onRequestClose={() => setShowGeneralInfoFormModal(false)}
            onEmployeeCreated={() => {
              setShowGeneralInfoFormModal(false)
              refetchEmployee()
            }}
            formData={employeeInfo}
          />
      }
    </AdminLayout>
  );
}
