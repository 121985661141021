import React, {useState, useEffect, useMemo} from 'react';
import { View } from 'react-native'

import api from "../../../../utils/api"
import tw from '../../../../styles/tailwind';
import Text from '../../../../components/Text';
import Icon from '../../../../components/Icon';
import Modal from '../../../../components/Modal';

import { Form, Select, Checkbox, Radio, Button } from '@douyinfe/semi-ui'; 
import useFormSubmit from '../../../../hooks/useFormSubmit';


export default function EditBankInfoForm({ onRequestClose, employeeId, formData, onInfoEdited }) {
  const [formApi, setFormApi] = useState<any>(null);

  const banksList = [
    { value: 'abc', label: 'Bancomer' },
    { value: 'hotsoon', label: 'Banamex' },
    { value: 'pipixia', label: 'Banco Maya' },
    { value: 'toutiao', label: 'Banorte' },
  ];

  const { submitForm, isSubmitting } = useFormSubmit({
    onSuccess: onInfoEdited,
    method: "put",
    onError: (error) => { },
    successToastMessage: "Información guardada",
    endpoint: `/employees/${employeeId}/private-info/payroll-info`,
  })

  function onFormSubmit() {
    formApi?.validate()
      .then((values) => {
        submitForm(values)
      })
      .catch((errors) => {
        console.log("yeerrorah", errors)
      });
  }


  return (
    <Modal
      title={"Editar información bancaria"}
      onRequestClose={onRequestClose}
      onOk={onFormSubmit}
      okText={'Guardar'}
      okButtonProps={{ loading: isSubmitting }}
    >
      <Form
        labelPosition={'left'}
        labelWidth={200}
        style={{
          paddingTop: '10px',
        }}
        getFormApi={setFormApi}
        initValues={formData}
      >
        <Form.Select
          label="Banco"
          field='salary_bank_name'
          style={{ width: 200 }}
          showClear
          optionList={banksList}

        />

        <Form.Input
          field="salary_bank_card"
          label="Número de tarjeta"
          maxLength={16}
          rules={[
            { type: 'string', message: 'RFC debe tener 16 caracteres', len: 16 },
          ]}
          trigger='blur'
        />

        <Form.Input
          field="salary_bank_clabe"
          label="CLABE"
          maxLength={18}
          rules={[
            { type: 'string', message: 'CLABE debe tener 18 caracteres', len: 18 },
          ]}
          trigger='blur'
        />


        <Form.Input
          field="salary_bank_account"
          label="Número de cuenta"
          maxLength={11}
          rules={[
            { type: 'string', message: 'Debe tener máximo 11 caracteres', max: 11 },
          ]}
          trigger='blur'
        />

      </Form>
    </Modal>
  );
}
