import React, { useState } from "react";
import { Button, Linking, StyleSheet, Text, TextInput, TouchableOpacity, View } from "react-native";
import api from "../../utils/api";
import tw from "../../styles/tailwind";
import Constants from 'expo-constants'
import { Image } from "expo-image";



export default function InvitationRegisterConfirmationScreen({navigation, route}) {
  function goToAppStore(){
    
  }
  return (

    <View style={tw`flex flex-1 items-center justify-center px-4`}>
      <Text style={tw`text-gray-600 mb-14 px-6 text-lg text-center`}>Por último, descarga la app para tener a la mano la gestión de tu perfil.</Text>

      <TouchableOpacity onPress={() => Linking.openURL("https://play.google.com/store/apps/details?id=com.ouvio.jobii&pli=1")}>
        <Image
          style={tw`h-18 w-80`}
          contentFit="contain"
          source={"https://play.google.com/intl/es-419/badges/static/images/badges/es-419_badge_web_generic.png"} />
      </TouchableOpacity>

      <TouchableOpacity onPress={() => Linking.openURL("https://apps.apple.com/app/id6463865039")} style={tw`mt-10`}>
        <Image
          style={tw`h-16 w-80`}
          contentFit="contain"
          source={"https://upload.wikimedia.org/wikipedia/commons/thumb/e/eb/Download_on_the_App_Store_Badge_ESMX_RGB_blk.svg/1280px-Download_on_the_App_Store_Badge_ESMX_RGB_blk.svg.png"} />
      </TouchableOpacity>
    </View>
  );
}


