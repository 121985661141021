import React, {useState, useEffect, useMemo} from 'react';
import { View } from 'react-native'

import api from "../../../../utils/api"
import tw from '../../../../styles/tailwind';
import Text from '../../../../components/Text';
import Icon from '../../../../components/Icon';
// import Button from '../../../../components/Forms/Button';
import Modal from '../../../../components/Modal';

import { Form, Select, Checkbox, Radio, Button, useFormState } from '@douyinfe/semi-ui'; 
import useFormSubmit from '../../../../hooks/useFormSubmit';
import { useQuery } from '@tanstack/react-query';


export default function NewEmployeeForm({ onRequestClose, onEmployeeCreated, formData }) {
  const [formApi, setFormApi] = useState<any>(null);
  const formState = useFormState();
  const [errorMessage, setErrorMessage] = useState();

  const { data: areas } = useQuery({
    queryKey: ['areas'],
    queryFn: () => api.get(`/areas`).then(({ data }) => data),
  })

  const { data: employees } = useQuery({
    queryKey: ['employees'],
    queryFn: () => api.get(`/employees/directory`).then(({ data }) => data),
  })

  const { data: work_positions } = useQuery({
    queryKey: ['work_positions'],
    queryFn: () => api.get(`/work_positions`).then(({ data }) => data),
  })

  
  const areasOptionsList = useMemo(() => {
    return areas?.map(area => {
      return {
        label: area.name,
        value: area.uuid,
      }
    })
  }, [areas])

  const employeesOptionsList = useMemo(() => {
    return employees?.map(area => {
      return {
        label: area.full_name,
        value: area.uuid,
      }
    })
  }, [employees])

  const workPositionsOptionsList = useMemo(() => {
    return work_positions?.map(area => {
      return {
        label: area.name,
        value: area.uuid,
      }
    })
  }, [work_positions])

  
  const { submitForm, isSubmitting } = useFormSubmit({
    onSuccess: onEmployeeCreated,
    method: formData ? "put" : "post",
    onError: (error) => {
      setErrorMessage(error.message)
    },
    successToastMessage: "Colaborador guardado",
    endpoint: formData ? `/employees/${formData.uuid}` : `/employees`,
  })


  function onFormSubmit() {
    setErrorMessage()

    formApi?.validate()
      .then((values) => {
        submitForm(values)
      })
      .catch((errors) => {
        console.log("yeerrorah", errors)
      });
  }

  const _formData = useMemo(() => {
    return formData ? {
      ...formData,
      boss_id: formData.boss?.uuid,
      area_id: formData.area?.uuid,
      work_position_id: formData.work_position?.uuid,
    } : null
  }, [formData])

  return (
    <Modal
      title={formData ? "Editar información general" : "Nuevo Colaborador"}
      onRequestClose={onRequestClose}
      onOk={onFormSubmit}
      okText={'Guardar'}
      okButtonProps={{ loading: isSubmitting }}
    >
      <Form
        labelPosition={'left'}
        labelWidth={200}
        style={{
          paddingTop: '10px',
        }}
        getFormApi={setFormApi}
        initValues={_formData}
      >
        {({ formState }) => (
          <>
            <Form.Input
              field="name"
              label="Nombre"
              trigger='blur'
              style={{ width: 200 }}
              rules={[
                { required: true, message: 'Requerido' },
              ]}
            />

            <Form.Input
              field="last_name"
              label="Primer Apellido"
              trigger='blur'
              style={{ width: 200 }}
              rules={[
                { required: true, message: 'Requerido' },
              ]}
            />

            <Form.Input
              field="last_name2"
              label="Segundo Apellido"
              trigger='blur'
              style={{ width: 200, padding: 0 }}
              rules={[
              ]}
            />

            <Form.DatePicker
              field="work_start_date"
              label='Fecha de ingreso' 
              placeholder='Elegir Fecha'
            />

            
            <Form.Select
              filter
              label="Jefe Directo"
              field='boss_id'
              style={{ width: 200 }}
              showClear
              optionList={employeesOptionsList}
            />
            
            <Form.Select
              label="Área"
              field='area_id'
              style={{ width: 200 }}
              showClear
              optionList={areasOptionsList}
            />
            
            <Form.Select
              label="Puesto"
              field='work_position_id'
              style={{ width: 200 }}
              showClear
              optionList={workPositionsOptionsList}
            />


            <View style={tw.style(`border-t border-b border-gray-200 py-2 my-2`, formData && `border-b-0`)}>
              {/* <Form.RadioGroup
                field="login_method"
                label="Método de inicio de sesión"
                labelPosition='top'
                labelWidth={'100%'}
                rules={[
                  { required: true, message: 'Requerido' },
                ]}
              >
                <Radio value="email">Correo electrónico</Radio>
                <Radio value="phone">Teléfono</Radio>
              </Form.RadioGroup> */}

              {/* {
                formState.values?.login_method === "email" && */}
                  <Form.Input
                    field="email"
                    label="Correo electrónico"
                    trigger='blur'
                    style={{ width: 200 }}
                    rules={[
                      { required: true, message: 'Requerido' },
                      { type: 'email', message: 'Formato incorrecto' }
                    ]}
                  />
              {/* } */}

              {/* {
                formState.values?.login_method === "email" && */}
                  <Form.Input
                    field="phone_number"
                    label="Teléfono"
                    trigger='blur'
                    style={{ width: 200 }}
                    maxLength={10}
                    rules={[
                      { message: 'Número incorrecto', validator: (rule, value) => ! value || /\d{10}/.test(value) }
                    ]}
                  />
              {/* } */}
            </View>
            
            {
              ! formData &&
                <Form.Switch
                  labelWidth={'calc(100% - 45px)'}
                  label="Activar Colaborador"
                  initValue={0}
                  field='active'
                />
            }

            {
              errorMessage &&
                <Text style={tw`text-red mt-3 mb-5`}>{errorMessage}</Text>
            }
          </>
        )}
      </Form>
    </Modal>
  );
}
