import React, {useEffect, useState} from 'react';
import { TouchableOpacity, View } from 'react-native'

import api from "../../../../utils/api"
import tw from '../../../../styles/tailwind';
import Text from '../../../../components/Text';

import { Form, TimePicker, useFormApi, useFormState } from '@douyinfe/semi-ui';
import useFormSubmit from '../../../../hooks/useFormSubmit';
import Modal from '../../../../components/Modal';
// /lib/es/timePicker/TimePicker



function DaysSelector({ onChange, initialValue=null }) {
  const [selectedDays, setSelectedDays] = useState(initialValue ?? {
    0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false,
  });
  

  function renderDay(label, dayIndex) {
    const isSelected = selectedDays[dayIndex];
    const isWeekend = dayIndex >= 5

    return (
      <TouchableOpacity
        style={tw.style(
          `h-6 w-6 mx-1 rounded-full bg-gray-200 items-center justify-center`,
          isWeekend && `bg-gray-300`,
          isSelected && `bg-primary`,
        )}
        onPress={() => {
          const newSelected = {
            ...selectedDays,
            [dayIndex]: !isSelected,
          }

          setSelectedDays(newSelected)
          onChange?.(newSelected)
        }}
      >
        <Text style={tw.style(isWeekend && `text-white`, isSelected && `text-white`)}>{label}</Text>
      </TouchableOpacity>
    )
  }

  return (
    <View style={tw`row`}>
      {renderDay("L", 0)}
      {renderDay("M", 1)}
      {renderDay("X", 2)}
      {renderDay("J", 3)}
      {renderDay("V", 4)}
      {renderDay("S", 5)}
      {renderDay("D", 6)}
    </View>
  )
}


function TimeTable({activeDays}){
  const days = ["General", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"]
  const formState = useFormState();
  const formApi = useFormApi();
  const { has_lunch_time, shifts } = formState.values


  const validateTime = (dayIndex, value) => {
    return ""
  }

  const renderDayRow = (dayIndex) => {

    return (
      <View style={tw`row`} key={dayIndex}>
        <Text style={tw`font-500 text-gray-600 w-26`}>{days[dayIndex]}</Text>
        <Form.Input
          hidden
          noLabel
          field={`shifts[${dayIndex}][day]`}
          initValue={dayIndex}
        />

        <Form.TimePicker
          use12Hours
          onChangeWithDateFirst={false}
          field={`shifts[${dayIndex}][start_time]`}
          noLabel
          showClear={false}
          style={tw`w-22 mr-3`}
          format={'HH:mm'}
          initValue={shifts?.[dayIndex] ? undefined : '09:00'}
        />

        <Form.TimePicker
          use12Hours
          onChangeWithDateFirst={false}
          field={`shifts[${dayIndex}][end_time]`}
          noLabel
          showClear={false}
          style={tw`w-22`}
          format={'HH:mm'}
          initValue={shifts?.[dayIndex] ? undefined : '17:00'}
        />

        {
          has_lunch_time &&
          <>
            <Form.TimePicker
              use12Hours
              onChangeWithDateFirst={false}
              field={`shifts[${dayIndex}][lunch_start]`}
              noLabel
              validate={value => validateTime(dayIndex, value)}
              showClear={false}
              style={tw`w-22 ml-6 mr-3`}
              format={'HH:mm'}
              initValue={shifts?.[dayIndex]?.lunch_start ? undefined : '15:00'}
            />
    
            <Form.TimePicker
              use12Hours
              onChangeWithDateFirst={false}
              field={`shifts[${dayIndex}][lunch_end]`}
              noLabel
              showClear={false}
              style={tw`w-22`}
              format={'HH:mm'}
              initValue={shifts?.[dayIndex]?.lunch_end ? undefined : '15:00'}
            />
          </>
        }
      </View>
    )
  }

  const renderTopRow = () => {
    return (
      <View style={tw`row`}>
        <Text style={tw`font-500 text-gray-600 w-26`}>General</Text>

        <TimePicker
          use12Hours
          onChangeWithDateFirst={false}
          showClear={false}
          onChange={date => fillDaysField('start_time', date)}
          style={tw`w-22 mr-3`}
          format={'HH:mm'}
          defaultValue={'09:00'}
        />
        
        <TimePicker
          use12Hours
          onChangeWithDateFirst={false}
          showClear={false}
          onChange={date => fillDaysField('end_time', date)}

          style={tw`w-22`}
          format={'HH:mm'}
          defaultValue={'17:00'}
        />

        {
          has_lunch_time &&
            <>
              <TimePicker
                use12Hours
                onChangeWithDateFirst={false}
                showClear={false}
                onChange={date => fillDaysField('lunch_start', date)}
                style={tw`w-22 ml-6 mr-3`}
                format={'HH:mm'}
                defaultValue={'09:00'}
              />

              <TimePicker
                use12Hours
                onChangeWithDateFirst={false}
                showClear={false}
                onChange={date => fillDaysField('lunch_end', date)}

                style={tw`w-22`}
                format={'HH:mm'}
                defaultValue={'17:00'}
              />
            </>
        }
      </View>
    )
  }


  const fillDaysField = (field, value) => {
    formState.values.days?.forEach(element => {
      if (element) {
        formApi.setValue(`shifts[${element.day}][${field}]`, value)
      }
    });
  }

  // console.log({ formState });
  

  return (
    <View style={tw`bg-gray-100 rounded-lg px-4 py-4`}>
      <View style={tw`row mb-3`}>
        <Text style={tw`w-26`}></Text>
        <Text style={tw`w-22 mr-3 text-center font-600`}>Entrada</Text>
        <Text style={tw`w-22 text-center font-600`}>Salida</Text>

        {
          has_lunch_time &&
            <Text style={tw`ml-6 w-48 text-center font-600`}>Comida</Text>
        }
      </View>

      {renderTopRow()}

      <View style={tw`border-b border-gray-200 my-3`}></View>

      {
        Array(7).fill(".").map((_, i) => activeDays[i] && renderDayRow(i + 1))
      }

    </View>
  )
}



const scheduleToFormData = schedule => {
  let has_lunch_time = false
  const selectedDays = {
    0: true, 1: true, 2: true, 3: true, 4: true, 5: false, 6: false,
  }

  return {
    ...schedule,
    shifts: Array(8).fill().map((_, i) => {
      if(i == 0) return
      const dayShift = schedule.shifts.find(shift => shift.day == i)

      if(dayShift) selectedDays[i - 1] = true
      if(dayShift?.lunch_start) has_lunch_time = true

      return dayShift
    }),
    has_lunch_time,
    selected_days: selectedDays,
  }
}

export default function ScheduleFormModal({ onRequestClose, onFormSubmitted, schedule=null }) {
  const [formApi, setFormApi] = useState<any>(null);

  const initialFormData = schedule ? scheduleToFormData(schedule) : null
  const [formData, setFormData] = useState(initialFormData);
  const [selectedDays, setSelectedDays] = useState(initialFormData ? initialFormData.selected_days : {
    0: true, 1: true, 2: true, 3: true, 4: true, 5: false, 6: false,
  });


  console.log({ initialFormData });
  

  const { submitForm, isSubmitting } = useFormSubmit({
    onSuccess: onFormSubmitted,
    method: schedule ? "put" :"post",
    onError: (error) => { },
    successToastMessage: "Horario guardado",
    endpoint: schedule ? `/assistance/schedules/${schedule.uuid}` : `/assistance/schedules`,
  })

  function onFormSubmit() {
    formApi?.validate()
      .then((values) => {
        const _values = {...values}
        _values.shifts = _values.shifts.filter(e => !!e)
        submitForm(_values)
      })
      .catch((errors) => {
        console.log("yeerrorah", errors)
      });
  }

  useEffect(() => {
    if(schedule){
      
    }
  }, [schedule]);


  return (
    <Modal
      title={`${schedule ? 'Editar' : 'Nuevo'} horario`}
      onRequestClose={onRequestClose}
      onOk={onFormSubmit}
      okText={'Guardar'}
      okButtonProps={{ loading: isSubmitting }}
    >
      <Form
        labelPosition={'left'}
        labelWidth={120}
        style={{
          paddingTop: '10px',
        }}
        getFormApi={setFormApi}
        initValues={formData}
      >
        {({ formState }) => (
          <>
            <Form.Input
              field="name"
              label="Nombre del horario"
              labelWidth={160}
              style={{width: 200}}
              rules={[
                { required: true, message: 'Requerido' },
              ]}
              trigger='blur'
            />

            <Form.Slot label="Días laborales">
              <DaysSelector onChange={setSelectedDays} initialValue={selectedDays} />
            </Form.Slot>

            <Form.Switch label="¿Agregar tiempo de comida?" labelWidth={210} field='has_lunch_time' />

            <TimeTable activeDays={selectedDays} />
          </>
        )}
      </Form>
    </Modal>
  );
}