import React, {useState, useEffect, useMemo} from 'react';
import { View } from 'react-native'

import api from "../../../../utils/api"
import tw from '../../../../styles/tailwind';
import Text from '../../../../components/Text';
import Icon from '../../../../components/Icon';
import Modal from '../../../../components/Modal';

import { Form, Select, Checkbox, Radio, Button } from '@douyinfe/semi-ui'; 
import useFormSubmit from '../../../../hooks/useFormSubmit';


export default function EditPersonalInfoForm({ onRequestClose, employeeId,  formData, onInfoEdited }) {
  const [formApi, setFormApi] = useState<any>(null);

  const statesList = [
    { value: 'abc', label: 'Aguascalientes' },
    { value: 'hotsoon', label: 'Arizona' },
    { value: 'pipixia', label: 'California' },
    { value: 'toutiao', label: 'TooBuzz' },
  ];

  const { submitForm, isSubmitting } = useFormSubmit({
    onSuccess: onInfoEdited,
    method: "put",
    onError: (error) => { },
    successToastMessage: "Información guardada",
    endpoint: `/employees/${employeeId}/private-info/general-info`,
  })

  function onFormSubmit() {
    formApi?.validate()
      .then((values) => {
        console.log("validated!", values)
        submitForm(values)
      })
      .catch((errors) => {
        console.log("yeerrorah", errors)
      });
  }

  return (
    <Modal
      title={"Editar datos personales"}
      onRequestClose={onRequestClose}
      okText={'Guardar'}
      onOk={onFormSubmit}
      okButtonProps={{ loading: isSubmitting }}
    >
      <Form
        labelPosition={'left'}
        labelWidth={200}
        style={{
          // padding: '10px',
        }}
        getFormApi={setFormApi}
        initValues={formData}
      >
        <Form.Select
          label="Estado civil"
          field='civil_status'
          style={{ width: 200 }}
          showClear
        >
          <Form.Select.Option value="soltero">Soltera/o</Form.Select.Option>
          <Form.Select.Option value="casado">Casada/o</Form.Select.Option>
        </Form.Select>


        <Form.DatePicker
          field="birthdate"
          insetInput
          format="dd/MM/yyyy"
          label='Fecha de nacimiento'
          placeholder=''
          disabledDate={date => {
            return date.getTime() > new Date();
          }}
        />

        <Form.Select
          field='birthplace'
          label="Lugar de nacimiento"
          style={{ width: 200 }}
          showClear
          optionList={statesList}
        />

        <Form.Input
          field="nationality"
          label="Nacionalidad"
          rules={[
          ]}
        />

        <Form.Input
          field="documents_rfc"
          label="RFC"
          maxLength={13}
          rules={[
            { type: 'string', message: 'RFC debe tener mínimo 12 caracteres', max: 13, min: 12 },
          ]}
          trigger='blur'
        />

        <Form.Input
          field="documents_clave_de_elector"
          label="INE"
          maxLength={18}
          rules={[
            { type: 'string', message: 'INE debe tener 18 caracteres', len: 18 },
          ]}
          trigger='blur'
        />

        <Form.Input
          field="documents_nss"
          label="NSS"
          maxLength={11}
          rules={[
            { type: 'string', message: 'NSS debe tener 11 caracteres', len: 11 },
          ]}
          trigger='blur'
        />

        <Form.Input
          field="documents_curp"
          label="CURP"
          maxLength={18}
          rules={[
            { type: 'string', message: 'CURP debe tener 18 caracteres', len: 18 },
          ]}
          trigger='blur'
        />

        <Form.RadioGroup
          field="gender"
          label="Género legal"
        >
          <Radio value="male">Hombre</Radio>
          <Radio value="female">Mujer</Radio>
        </Form.RadioGroup>


        <Form.Select
          label="Nivel de estudios"
          field='nivel_de_estudios'
          style={{ width: 200 }}
          showClear
        >
          <Form.Select.Option value="Primaria">Primaria</Form.Select.Option>
          <Form.Select.Option value="Secundaria">Secundaria</Form.Select.Option>
          <Form.Select.Option value="Bachillerato">Bachillerato</Form.Select.Option>
          <Form.Select.Option value="Licenciatura">Licenciatura</Form.Select.Option>
          <Form.Select.Option value="Maestría">Maestría</Form.Select.Option>
          <Form.Select.Option value="Doctorado">Doctorado</Form.Select.Option>
        </Form.Select>
      </Form>
    </Modal>
  );
}
