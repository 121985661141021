/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import React, { useEffect, useState } from "react";
import { FontAwesome } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { ColorSchemeName, Platform, Pressable } from 'react-native';

import tw from "../styles/tailwind";
import useColorScheme from '../hooks/useColorScheme';



import { RootStackParamList, RootTabParamList, RootTabScreenProps } from '../types';
import LinkingConfiguration from './LinkingConfiguration';

// 
import { useSelector, useDispatch } from "react-redux";
import { updateStatus } from "../store/Reducers/status";


/**
 * Import de las pantallas
 */
import LoginScreen from '../screens/Auth/LoginScreen'
import NotFoundScreen from '../screens/NotFoundScreen';

import AppStackNavigator from "./AppStack";
import PrivacyScreen from "../screens/ToS/PrivacyScreen";
import SupportScreen from "../screens/ToS/SupportScreen";
import RegisterScreen from "../screens/Auth/RegisterScreen";
import InvitationRegisterConfirmationScreen from "../screens/Auth/InvitationRegisterConfirmationScreen";


const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    // primary: 'rgb(255, 45, 85)',
    // background: theme.BACKGROUND_COLOR,
    border: "transparent",
  },
};



export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      //
      // si se quiere usar tema oscuro hay que descomentar esto
      //
      // theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}
      >
      {/*
        Este se puede intercambiar a el RootTabNavigator que 
        trae la app demo de Expo
      */}
      <RootNavigator />
      {/* <RootTabNavigator /> */}
    </NavigationContainer>
  );
}



/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();



function RootNavigator() {
  const auth = useSelector((state) => state.auth);
  const status = useSelector((state) => state.status);
  const dispatch = useDispatch()

  // Despachamos la accion para actualizar el status de la app
  useEffect(() => {
    dispatch(updateStatus())
  }, [auth.isLogged]);

  return (
    <Stack.Navigator
      screenOptions={{
        // Ejemplo de customizacion del header
        headerStyle: {
          backgroundColor: tw.color('primary'),
        },
        headerTintColor: tw.color('secondary'),
        headerTitleStyle: {
          fontWeight: 'bold',
        },
        // headerShown: false, // si se quiere escondido
      }}
    >
      {auth.isLogged ? (
        <>
          {/* 
            Rutas protegidas
          */}
          <Stack.Screen
            name={Platform.OS === "web" ? "Admin" : "App"}
            component={AppStackNavigator}
            options={{ title: "", headerShown: false }}
          />

        </>
      ) : (
        <>
          {/* El stack de Auth */}
          <Stack.Screen
            name="Auth.Login"
            component={LoginScreen}
            options={{ title: "Iniciar sesión" }}
          />

          <Stack.Screen
            name="Auth.Register"
            component={RegisterScreen}
            options={{ title: "Completar Perfil" }}
          />

          <Stack.Screen
            name="Auth.Register.Confirmation"
            component={InvitationRegisterConfirmationScreen}
            options={{ title: "", headerShown:false }}
          />
        </>
      )}


      {/* Aquí se agregan rutas que se acceden logueado o deslogueado */}
      <Stack.Screen
        name="ToS.Privacy"
        component={PrivacyScreen}
        options={{ title: "Privacidad" }}
      />
      <Stack.Screen
        name="Support.Index"
        component={SupportScreen}
        options={{ title: "Iniciar sesión" }}
      />

    </Stack.Navigator>
  )
}


