import React, {useState, useEffect, useMemo} from 'react';
import { View } from 'react-native'

import api from "../../../../utils/api"
import tw from '../../../../styles/tailwind';
import Text from '../../../../components/Text';
import Icon from '../../../../components/Icon';
import Modal from '../../../../components/Modal';

import { Form, Select, Checkbox, Radio, Button } from '@douyinfe/semi-ui'; 
import useFormSubmit from '../../../../hooks/useFormSubmit';


export default function EditEmergencyContactInfoForm({ onRequestClose, employeeId, formData, onInfoEdited }) {
  const [formApi, setFormApi] = useState<any>(null);



  const { submitForm, isSubmitting } = useFormSubmit({
    onSuccess: onInfoEdited,
    method: "put",
    onError: (error) => { },
    successToastMessage: "Información guardada",
    endpoint: `/employees/${employeeId}/private-info/general-info`,
  })


  function onFormSubmit() {
    formApi?.validate()
      .then((values) => {
        // console.log("validated", values)
        submitForm(values)
      })
      .catch((errors) => {
        console.log("yeerrorah", errors)
      });
  }

  return (
    <Modal
      title={"Editar contacto de emergencia"}
      onRequestClose={onRequestClose}
      onOk={onFormSubmit}
      okText={'Guardar'}
      okButtonProps={{ loading: isSubmitting }}
    >
      <Form
        labelPosition={'left'}
        labelWidth={200}
        style={{
          paddingTop: '10px',
        }}
        getFormApi={setFormApi}
        initValues={formData}
      >
        <Form.Input
          field="contact_name"
          label="Nombre del contacto"
          trigger='blur'
          style={{ width: 200 }}
          rules={[
          ]}
        />

        <Form.Input
          field="contact_relationship"
          label="Parentesco"
          trigger='blur'
          style={{ width: 200 }}
          rules={[
          ]}
        />

        {/* <Form.Input
          field="name"
          label="Celular"
          trigger='blur'
          style={{ width: 200 }}
          maxLength={10}
          rules={[
            { message: 'Número incorrecto', validator: (rule, value) => /\d{10}/.test(value) }
          ]}
        /> */}

        <Form.Input
          field="contact_phone"
          label="Teléfono"
          trigger='blur'
          style={{ width: 200 }}
          maxLength={10}
          rules={[
            { message: 'Número incorrecto', validator: (rule, value) => ! value || /\d{10}/.test(value) }
          ]}
        />

        <Form.Input
          field="contact_email"
          label="Correo electrónico"
          trigger='blur'
          style={{ width: 200 }}
          rules={[
            { type: 'email', message: 'Formato incorrecto'}
          ]}
        />

      </Form>
    </Modal>
  );
}
