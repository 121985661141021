import React, { useState, useEffect, useMemo } from 'react';
import { View } from 'react-native'

import api from "../../../utils/api"
import tw from '../../../styles/tailwind';
import Text from '../../../components/Text';
import Icon from '../../../components/Icon';


import dayjs from 'dayjs'
import 'dayjs/locale/es'
dayjs.locale('es')

import * as dateFns from 'date-fns';
import { Link } from '@react-navigation/native';



import { Table, Avatar, Input } from '@douyinfe/semi-ui';
import { IconSearch } from '@douyinfe/semi-icons';
import { useQuery } from '@tanstack/react-query';

const DAY = 24 * 60 * 60 * 1000;
const figmaIconUrl = 'https://lf3-static.bytednsdoc.com/obj/eden-cn/ptlz_zlp/ljhwZthlaukjlkulzlp/figma-icon.png';


const renderGrayTextColumn = text => <Text style={tw`text-gray-400`}>{text}</Text>


const columns = [
  {
    title: 'Nombre',
    dataIndex: 'employee.full_name',
    fixed: true,
    width: 260,
    render: (text, record, index) => {
      return (
        <div>
          {/* <Avatar size="small" shape="square" src={figmaIconUrl} style={{ marginRight: 12 }}></Avatar> */}
          <Avatar size="extra-small" color={record.avatarBg} style={{ marginRight: 6 }}>
            {typeof text === 'string' && text.slice(0, 1)}
          </Avatar>

          <Link to={`/colaboradores/${record.uuid}`} style={tw`font-600 text-gray-600`}>{text}</Link>
        </div>
      );
    },
    onFilter: (value, record) => record.name.includes(value),
    sorter: (a, b) => (a.name.length - b.name.length > 0 ? 1 : -1),
  },

  {
    title: 'Fecha',
    dataIndex: 'start_datetime',
    sorter: (a, b) => (a.start_datetime > b.start_datetime ? 1 : -1),
    width: 115,
    ellipsis: true,
    render: value => {
      return renderGrayTextColumn(dayjs(value).format('D MMM YYYY'));
    },
  },
  {
    title: 'Entrada',
    dataIndex: 'start_datetime',
    sorter: (a, b) => (a.start_datetime > b.start_datetime ? 1 : -1),
    width: 110,
    ellipsis: true,
    render: value => {
      return renderGrayTextColumn(dayjs(value).format('HH:mm'));
    },
  },
  {
    title: 'Estado entrada',
    dataIndex: 'start_status_record_str',
    sorter: (a, b) => (a.area > b.area ? 1 : -1),
    width: 155,
    ellipsis: true,
    render: (value, record) => <Text style={tw.style(record.start_status_record != "on_time" ? 'text-red' : 'text-gray-400')}>{value}</Text>,
  },
  {
    title: 'Comida (inicio)',
    dataIndex: 'TODEFINE',
    width: 140,
    ellipsis: true,
    render: renderGrayTextColumn,
  },
  {
    title: 'Comida (fin)',
    dataIndex: 'TODEFINE',
    width: 120,
    ellipsis: true,
    render: renderGrayTextColumn,
  },
  {
    title: 'Salida',
    dataIndex: 'end_datetime',
    width: 90,
    ellipsis: true,
    render: value => {
      return renderGrayTextColumn(value ? dayjs(value).format('HH:mm') : "--:--");
    },
  },
  {
    title: 'Estado salida',
    dataIndex: 'end_status_record_str',
    sorter: (a, b) => (a.area > b.area ? 1 : -1),
    width: 150,
    ellipsis: true,
    render: (value, record) => <Text style={tw.style((record.end_status_record != "on_time" && record.end_status_record != "no_register") ? 'text-red' : 'text-gray-400')}>{value}</Text>,
  },
  {
    title: 'Área',
    dataIndex: 'employee.area.name',
    width: 150,
    ellipsis: true,
    render: renderGrayTextColumn,
  },
  {
    title: 'Horario',
    dataIndex: 'employee.location.name',
    width: 150,
    ellipsis: true,
    // onFilter: (value, record) => record.name.includes(value),
    render: (value, record) => <Text style={tw.style(`text-gray-400`)}>{record.shift_data?.start_time ? `${record.shift_data.start_time} - ${record.shift_data.end_time}` : 'Sin horario'}</Text>,
  },
  {
    title: 'Oficina',
    dataIndex: 'employee.location.name',
    width: 150,
    ellipsis: true,
    onFilter: (value, record) => record.name.includes(value),
    render: renderGrayTextColumn,
  },
  {
    title: 'Puesto',
    dataIndex: 'employee.work_position.name',
    width: 260,
    ellipsis: true,
    render: renderGrayTextColumn,
  },
];



export function useTablePagination(){
  // const pageSize = 5
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setPage] = useState(1);

  const onChange = (data = {}) => {
    console.log('Table changed: ', data);
    let { pagination, sorter, filters } = data;

    const dataPagination = {
      current_page: pagination.currentPage,
      page_size: pagination.pageSize,
    }

    const dataFilters = filters.map(filter => {
      return {
        index: filter.dataIndex,
        values: filter.filteredValue
      }
    })

    const dataSort = (sorter && sorter.sortOrder) ? {
      index: sorter.dataIndex,
      order: sorter.sortOrder,
    } : null

    console.log('Filters', { dataFilters, dataSort, dataPagination });
    setPage(pagination.currentPage)
  }

  return {
    pageSize,
    currentPage,
    onChange,
    setPage,
  }
}



export default function AssistanceRegistersTable({ onRowsSelected }) {
  const { pageSize, currentPage, onChange } = useTablePagination()

  const { data: employeesResult, isLoading, refetch } = useQuery({
    queryKey: ['assistance.records', { currentPage }],
    queryFn: getAssistanceDirectory,
  })

  function getAssistanceDirectory({ queryKey }) {
    console.log("getAssistanceDirectory", queryKey);

    return api.get(`/assistance/records?pagination=true`, {
      params: {
        page: queryKey[1].currentPage
      }
    })
      .then(({ data }) => data)
  }



  const rowSelection = useMemo(
    () => ({
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        onRowsSelected?.(selectedRows)
      },
      getCheckboxProps: record => ({
        disabled: record.name === 'Michael James', // Column configuration not to be checked
        name: record.name,
      }),
      fixed: true,
    }),
    []
  );

  const scroll = useMemo(() => ({ y: 500 }), []);



  return (
    <>
      <style>{".semi-table-pagination-outer{padding: 0 30px}"}</style>
      <style>{".semi-table-thead > .semi-table-row > .semi-table-row-head{padding-left:5px;padding-right:5px;}"}</style>
      <style>{".semi-table-small .semi-table-tbody > .semi-table-row > .semi-table-row-cell{padding-left:5px;padding-right:5px;}"}</style>

      <Table
        size='small'
        columns={columns}
        dataSource={employeesResult?.data}
        rowKey={'uuid'}
        rowSelection={rowSelection}
        scroll={scroll}
        onChange={onChange}
        loading={isLoading}
        style={tw.style('bg-white rounded-lg border border-gray-100 overflow-hidden')}
        pagination={{
          currentPage,
          pageSize,
          total: employeesResult?.total,
          // showSizeChanger: true,
          // pageSizeOpts: [10, 20, 50, 200],
          // onPageChange: handlePageChange,
        }}
      />
    </>
  )
}