import { RootStackParamList, RootTabParamList, RootTabScreenProps } from '../types';

import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
const Stack = createNativeStackNavigator();

import Icon from '../components/Icon';

import { Image } from 'expo-image';
import tw from '../styles/tailwind';


import ComponentsTestsScreen from '../screens/ComponentsTests/ComponentsTestsScreen';
import AdminHomeScreen from '../screens/Admin/AdminHomeScreen';
import EmployeesScreen from '../screens/Admin/Employees/EmployeesScreen';
import AssistanceScreen from '../screens/Admin/Assistance/AssistanceScreen';
import IncidencesScreen from '../screens/Admin/Incidences/IncidencesScreen';
import SettingsScreen from '../screens/Admin/Settings/SettingsScreen';
import EmployeesDetailScreen from '../screens/Admin/Employees/EmployeesDetailScreen';
import AssistanceSchedulesScreen from '../screens/Admin/Assistance/AssistanceSchedulesScreen';





export default function AdminStackNavigator() {
  return (
    <Stack.Navigator screenOptions={{headerShown: false}}>
      {/* <Stack.Screen
        name="ComponentsTestsScreen"
        component={ComponentsTestsScreen}
        options={{ headerShown: false }}
      /> */}
      
      <Stack.Screen
        name="Home"
        component={AdminHomeScreen}
        options={{ headerShown: false }}
      />
      
      {/* Colaboradores */}
      <Stack.Screen
        name="Employees.Index"
        component={EmployeesScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Employees.Detail"
        component={EmployeesDetailScreen}
        // options={{ headerShown: false }}
      />
      


      <Stack.Screen
        name="Assistance.Index"
        component={AssistanceScreen}
        options={{ headerShown: false }}
      />

      <Stack.Screen
        name="Assistance.Schedules"
        component={AssistanceSchedulesScreen}
        options={{ headerShown: false }}
      />
      


      <Stack.Screen
        name="Incidences.Index"
        component={IncidencesScreen}
        options={{ headerShown: false }}
      />
      
      <Stack.Screen
        name="Settings.Index"
        component={SettingsScreen}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
}