import React, { useState, useEffect, useMemo, useContext } from 'react';
import { View } from 'react-native'

import api from "../../../utils/api"
import tw from '../../../styles/tailwind';
import Text from '../../../components/Text';
import Icon from '../../../components/Icon';


import dayjs from 'dayjs'
import 'dayjs/locale/es'
dayjs.locale('es')

import { createContext } from 'react';

export const RowSchedulesContext = createContext(1);



import { Table, Avatar, Input, Select } from '@douyinfe/semi-ui';
import { IconSearch } from '@douyinfe/semi-icons';
import { useQuery } from '@tanstack/react-query';
import { useTablePagination } from './AssistanceRegistersTable';
import { useToast } from 'react-native-toast-notifications';

const DAY = 24 * 60 * 60 * 1000;
const figmaIconUrl = 'https://lf3-static.bytednsdoc.com/obj/eden-cn/ptlz_zlp/ljhwZthlaukjlkulzlp/figma-icon.png';


const renderGrayTextColumn = text => <Text style={tw`text-gray-400`}>{text}</Text>


const renderShiftDayCell = (dayIndex, register) => {
  if (!register.assistance_schedule) {
    return <Text style={tw`text-gray-400`}>Sin asignar</Text>
  }

  const dayShift = register.assistance_schedule.shifts.find(e => e.day == dayIndex)

  if (!dayShift) {
    return <Text style={tw`text-gray-500`}>Descanso</Text>
  } else {
    return <Text style={tw`text-gray-500`}>{dayShift.start_time} - {dayShift.end_time}</Text>
  }
  return
}

function CellScheduleSelect({value, employeeId}){
  const rowScheduleContext = useContext(RowSchedulesContext);

  console.log({rowScheduleContext, value});
  const toast = useToast()
  
  const options = rowScheduleContext.schedules?.map(schedule => {
    return {
      value: schedule.uuid,
      label: schedule.name,
    }
  })


  function onScheduleSelected(scheduleId){
    api.post(`/assistance/schedules/${scheduleId}/assign_employees`, { employees: [employeeId]})
    .then(({data}) => {
      toast.show("Horario asignado")
      rowScheduleContext.refetchTable()
    })
    .catch(({response}) => {})

  }

  return <Select
    placeholder="Sin horario asignado"
    style={{ width: 180 }}
    onChange={onScheduleSelected}
    optionList={options}
    value={value} />;
}


const columns = [
  {
    title: 'Nombre',
    dataIndex: 'full_name',
    fixed: true,
    width: 260,
    render: (text, record, index) => {
      return (
        <div>
          <Avatar size="extra-small" src={record.avatar} style={{ marginRight: 6 }}></Avatar>

          <Text style={tw`font-600 text-gray-500`}>{text}</Text>
        </div>
      );
    },
    onFilter: (value, record) => record.name.includes(value),
    sorter: (a, b) => (a.name.length - b.name.length > 0 ? 1 : -1),
  },

  {
    title: 'Lunes',
    dataIndex: 'monday',
    width: 135,
    ellipsis: true,
    render: (_, register) => renderShiftDayCell(1, register),
  },

  {
    title: 'Martes',
    dataIndex: 'tuesday',
    width: 135,
    ellipsis: true,
    render: (_, register) => renderShiftDayCell(2, register),

  },

  {
    title: 'Miércoles',
    dataIndex: 'wednesday',
    width: 135,
    ellipsis: true,
    render: (_, register) => renderShiftDayCell(3, register),

  },

  {
    title: 'Jueves',
    dataIndex: 'thursday',
    width: 135,
    ellipsis: true,
    render: (_, register) => renderShiftDayCell(4, register),

  },

  {
    title: 'Viernes',
    dataIndex: 'friday',
    width: 135,
    ellipsis: true,
    render: (_, register) => renderShiftDayCell(5, register),

  },

  {
    title: 'Sábado',
    dataIndex: 'saturday',
    width: 135,
    ellipsis: true,
    render: (_, register) => renderShiftDayCell(6, register),

  },

  {
    title: 'Doming',
    dataIndex: 'sunday',
    width: 135,
    ellipsis: true,
    render: (_, register) => renderShiftDayCell(7, register),
  },

  {
    title: 'Horario',
    dataIndex: 'assistance_schedule.uuid',
    width: 200,
    ellipsis: false,
    render: (value, register) => <CellScheduleSelect value={value} employeeId={register.uuid} />,
  },
  
  // {
  //   title: 'Área',
  //   dataIndex: 'employee.area.name',
  //   width: 150,
  //   ellipsis: true,
  //   render: renderGrayTextColumn,
  // },
  // {
  //   title: 'Oficina',
  //   dataIndex: 'employee.location.name',
  //   width: 150,
  //   ellipsis: true,
  //   onFilter: (value, record) => record.name.includes(value),
  //   render: renderGrayTextColumn,
  // },
  // {
  //   title: 'Puesto',
  //   dataIndex: 'employee.work_position.name',
  //   width: 260,
  //   ellipsis: true,
  //   render: renderGrayTextColumn,
  // },
];





export default function AssistanceEmployeesSchedulesTable({ onRowsSelected }) {
  const { pageSize, currentPage, onChange } = useTablePagination()


  const { data: employeesResult, isLoading, refetch } = useQuery({
    queryKey: ['assistance.employees-schedule', { currentPage }],
    queryFn: getEmployeesSchedules,
  })

  const { data: schedules } = useQuery({
    queryKey: ['assistance.schedules'],
    queryFn: () => api.get(`/assistance/schedules`).then(({ data }) => data),
  })


  function getEmployeesSchedules({ queryKey }) {
    console.log("getEmployeesSchedules", queryKey);

    return api.get(`/assistance/employees_schedules?pagination=true`, {
      params: {
        page: queryKey[1].currentPage
      }
    })
      .then(({ data }) => data)
  }



  const rowSelection = useMemo(
    () => ({
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        onRowsSelected?.(selectedRows)
      },
      getCheckboxProps: record => ({
        disabled: record.name === 'Michael James', // Column configuration not to be checked
        name: record.name,
      }),
      fixed: true,
    }),
    []
  );

  const scroll = useMemo(() => ({ y: 500 }), []);


  return (
    <>
      <style>{".semi-table-pagination-outer{padding: 0 30px}"}</style>
      <style>{".semi-table-thead > .semi-table-row > .semi-table-row-head{padding-left:5px;padding-right:5px;}"}</style>
      <style>{".semi-table-small .semi-table-tbody > .semi-table-row > .semi-table-row-cell{padding-left:5px;padding-right:5px;}"}</style>

      <RowSchedulesContext.Provider value={{ refetchTable: refetch, schedules }}>
        <Table
          size='small'
          columns={columns}
          dataSource={employeesResult?.data}
          rowKey={'uuid'}
          rowSelection={rowSelection}
          scroll={scroll}
          onChange={onChange}
          loading={isLoading}
          style={tw.style('bg-white rounded-lg border border-gray-100 overflow-hidden')}
          pagination={{
            currentPage,
            pageSize,
            total: employeesResult?.total,
            // showSizeChanger: true,
            // pageSizeOpts: [10, 20, 50, 200],
            // onPageChange: handlePageChange,
          }}
        />
      </RowSchedulesContext.Provider>
    </>
  )
}