import React, { useState } from "react";
import { Button, StyleSheet, Text, TextInput, View } from "react-native";
import { StatusBar } from "expo-status-bar";
import { useSelector, useDispatch } from 'react-redux';
import { actions } from "../../store/Reducers/auth";
import Input from "../../components/Forms/Input";
import { useForm } from "react-hook-form";
import api from "../../utils/api";
import tw from "../../styles/tailwind";
import Constants from 'expo-constants'



export default function LoginScreen(props) {
  const dispatch = useDispatch()
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const { control, handleSubmit, formState, formState: { errors }, setError, setValue, watch } = useForm();

  function submitForm(data) {
    console.log(data);

    setSubmitting(true)
    setErrorMessage(null)

    api.post(`/auth/login`, data)
      .then(({ data }) => {
        dispatch(actions.login({}, data.token))
      })
      .catch(({ response }) => {
        setSubmitting(false)
        setErrorMessage(response.data?.message)
      })
    // dispatch(actions.login({id: 123, name: "paco"}, "new token"))
  }

  return (

    <View style={styles.container} >

      <Input
        name='username'
        control={control}
        placeholder="Correo Electrónico"
        rules={{ required: true }}
        keyboardType="email-address"
        style={{ container: tw`w-60` }}
      />

      <Input
        name='password'
        control={control}
        placeholder="Contraseña"
        rules={{ required: true }}
        style={{ container: tw`w-60` }}
        secureTextEntry
      />


      {
        errorMessage &&
        <Text style={tw`text-red text-center mb-4`}>{errorMessage}</Text>
      }

      <Button
        title={submitting ? "Iniciando sesión..." : "Iniciar sesión"}
        disabled={submitting}
        onPress={handleSubmit(submitForm)}
        color={tw.color('primary')}
      />

      <Text style={tw`text-gray-300 text-center mt-20`}>v{Constants.expoConfig?.version}</Text>

    </View>
  );
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    paddingVertical: 15,
    paddingHorizontal: 45,
    marginBottom: 25,
    borderBottomColor: "#888",
    borderBottomWidth: 1,
  },
});
