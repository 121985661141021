import React, { useState } from "react";
import { Button, StyleSheet, Text, TextInput, View } from "react-native";
import { StatusBar } from "expo-status-bar";
import { useSelector, useDispatch } from 'react-redux';
import { actions } from "../../store/Reducers/auth";
import Input from "../../components/Forms/Input";
import { useForm } from "react-hook-form";
import api from "../../utils/api";
import tw from "../../styles/tailwind";
import Constants from 'expo-constants'



export default function RegisterScreen({navigation, route}) {
  const dispatch = useDispatch()
  const {user, token} = route.params
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const { control, handleSubmit, formState, formState: { errors }, setError, clearErrors, watch } = useForm();

  function submitForm(data) {
    const _data = {
      ...data,
      user,
      token,
    }
    console.log(_data);

    clearErrors()


    if(data.password != data.password_repeat){
      setError("password_repeat", "Las contraseñas no coinciden")
      setErrorMessage("Las contraseñas no coinciden")
      return
    }

    setSubmitting(true)
    setErrorMessage(null)

    api.post(`/auth/register`, _data)
      .then(({ data }) => {
        // dispatch(actions.login({}, data.token))
        navigation.navigate("Auth.Register.Confirmation")
      })
      .catch(({ response }) => {
        setSubmitting(false)
        setErrorMessage(response.data?.message)
      })
    // dispatch(actions.login({id: 123, name: "paco"}, "new token"))
  }

  return (

    <View style={styles.container}>

      <Text style={tw`text-gray-600 mb-10 px-6`}>Para terminar de completar tu perfil solo necesitas definir una contraseña para entrar a tu cuenta.</Text>

      <Text style={tw`text-gray-700 text-lg font-500 mb-10 px-6`}>{user}</Text>

      <Input
        name='password'
        control={control}
        placeholder="Definir contraseña"
        rules={{ required: true }}
        style={{ container: tw`w-60` }}
        secureTextEntry
      />

      <Input
        name='password_repeat'
        control={control}
        placeholder="Repetir Contraseña"
        rules={{ required: true }}
        style={{ container: tw`w-60` }}
        secureTextEntry
      />


      {
        errorMessage &&
        <Text style={tw`text-red text-center mb-4`}>{errorMessage}</Text>
      }

      <Button
        title={submitting ? "Enviando..." : "Finalizar mi cuenta"}
        disabled={submitting}
        onPress={handleSubmit(submitForm)}
        color={tw.color('primary')}
      />

      <Text style={tw`text-gray-300 text-center mt-20`}>v{Constants.expoConfig?.version}</Text>

    </View>
  );
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    paddingVertical: 15,
    paddingHorizontal: 45,
    marginBottom: 25,
    borderBottomColor: "#888",
    borderBottomWidth: 1,
  },
});
