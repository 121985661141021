import * as React from 'react';
import { View } from 'react-native'

import api from "../../../utils/api"
import tw from '../../../styles/tailwind';
import Text from '../../../components/Text';
import Icon from '../../../components/Icon';
import AdminLayout from '../../../navigation/AdminLayout';
import { Button, RadioGroup, Radio } from '@douyinfe/semi-ui';
import { IconFile } from '@douyinfe/semi-icons'
import AssistanceRegistersTable from './AssistanceRegistersTable';
import { useLinkTo } from '@react-navigation/native';



export function AssistanceScreenMenu(){
  const linkTo = useLinkTo()
  return (
    <RadioGroup
      type="button"
      buttonSize="middle"
      onChange={(event)=>{
        if (event.target.value){
          linkTo(event.target.value)
        }
      }}
    // value={this.state.value2}
    >
      <Radio value={"/checador"}>Checador</Radio>
      <Radio value={""}>Políticas</Radio>
      <Radio value={"/checador/horarios"}>Horarios</Radio>
    </RadioGroup>
  )
}


export default function AssistanceScreen({ navigation }) {
  function downloadRecordsExcel(){
    downloadFile(`/assistance/records/download`)
  }
  function downloadAssistanceReportExcel(){
    downloadFile(`/assistance/report/download`)
  }

  function downloadFile(path){
    let filename
    api.post(path, {}, {responseType: 'blob', timeout: 30000})
    // .then((response) => {
    //   console.log(response.data);
    //   return response.blob()
    // })
    // })
    .then(response => {
      const disposition = response.headers['content-disposition'];
      console.log(disposition, response.headers);
      filename = "pero.xlsx"
      
      filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
      if (filename.toLowerCase().startsWith("utf-8''"))
          filename = decodeURIComponent(filename.replace("utf-8''", ''));
      else
          filename = filename.replace(/['"]/g, '');

      return response.data;
    })
    .then(blob => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a); // append the element to the dom
      a.click();
      a.remove(); // afterwards, remove the element  
    })
    .catch((error)=>{
      console.error(error);
    })
  }

  return (
    <AdminLayout title={"Asistencia"}>
      <View style={tw`row justify-between mb-4`}>
        <View></View>

        <View style={tw`row`}>
          
          <AssistanceScreenMenu />

          <Button onClick={downloadRecordsExcel} icon={<IconFile />}>Descargar</Button>
          <Button onClick={downloadAssistanceReportExcel} icon={<IconFile />}>Reporte Asistencia</Button>
        </View>
      </View>

      <AssistanceRegistersTable onRowsSelected={() => {}} />

      {/* <pre>
- 🏆 Lista de asistencias y faltas{"\n"}
- 🏆 Descargar excel registros{"\n"}
- 🏆 [App Colaborador] Checar asistencia con QR
      </pre> */}
    </AdminLayout>
  );
}
