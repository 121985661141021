import React, {useState, useEffect, useMemo} from 'react';
import { View } from 'react-native'

import api from "../../../utils/api"
import tw from '../../../styles/tailwind';
import Text from '../../../components/Text';
import Icon from '../../../components/Icon';
import AdminLayout from '../../../navigation/AdminLayout';



import * as dateFns from 'date-fns';
import { Link } from '@react-navigation/native';
import NewEmployeeForm from './Forms/NewEmployeeForm';


import { Table, Avatar, Input, Button } from '@douyinfe/semi-ui';
import { IconSearch } from '@douyinfe/semi-icons';
import { useQuery } from '@tanstack/react-query';
import EmployeeSendInvitationModal from './Forms/EmployeeSendInvitationModal';
import { useTablePagination } from '../Assistance/AssistanceRegistersTable';

const DAY = 24 * 60 * 60 * 1000;
const figmaIconUrl = 'https://lf3-static.bytednsdoc.com/obj/eden-cn/ptlz_zlp/ljhwZthlaukjlkulzlp/figma-icon.png';


const renderGrayTextColumn = text => <Text style={tw`text-gray-400`}>{text}</Text>


const columns = [
  {
    title: 'Nombre',
    dataIndex: 'full_name',
    fixed: true,
    width: 260,
    render: (text, record, index) => {
      return (
        <div>
          {/* <Avatar size="small" shape="square" src={figmaIconUrl} style={{ marginRight: 12 }}></Avatar> */}
          <Avatar size="extra-small" color={record.avatarBg} style={{ marginRight: 6 }}>
            {typeof text === 'string' && text.slice(0, 1)}
          </Avatar>

          <Link to={`/colaboradores/${record.uuid}`} style={tw`font-600 text-gray-600`}>{text}</Link>
        </div>
      );
    },
    filters: [
      {
        text: 'Semi Design design draft',
        value: 'Semi Design design draft',
      },
      {
        text: 'Semi D2C design draft',
        value: 'Semi D2C design draft',
      },
    ],
    onFilter: (value, record) => record.name.includes(value),
    sorter: (a, b) => (a.name.length - b.name.length > 0 ? 1 : -1),
  },

  {
    title: 'Área',
    dataIndex: 'area.name',
    sorter: (a, b) => (a.area > b.area ? 1 : -1),
    width: 200,
    ellipsis: true,
    render: renderGrayTextColumn,
  },
  {
    title: 'Oficina',
    dataIndex: 'location.name',
    width: 150,
    ellipsis: true,
    filters: [
      {
        text: 'Semi Design design draft',
        value: 'Semi Design design draft',
      },
      {
        text: 'Semi D2C design draft',
        value: 'Semi D2C design draft',
      },
    ],
    onFilter: (value, record) => record.name.includes(value),
    render: renderGrayTextColumn,
    // render: (text, record, index) => {
    //   return (
    //     <div>
    //       <Avatar size="small" color={record.avatarBg} style={{ marginRight: 4 }}>
    //         {typeof text === 'string' && text.slice(0, 1)}
    //       </Avatar>
    //       {text}
    //     </div>
    //   );
    // },
  },
  {
    title: 'Puesto',
    dataIndex: 'work_position.name',
    width: 260,
    ellipsis: true,
    render: renderGrayTextColumn,
  },
  {
    title: 'Jefe',
    dataIndex: 'boss.full_name',
    width: 230,
    ellipsis: true,
    render: text => <Text style={tw`font-600 text-gray-600`}>{text}</Text>,
    // sorter: (a, b) => (a.updateTime - b.updateTime > 0 ? 1 : -1),
  },
  {
    title: 'Ingreso',
    dataIndex: 'work_start_date',
    width: 140,
    ellipsis: true,
    // sorter: (a, b) => (a.updateTime - b.updateTime > 0 ? 1 : -1),
    render: value => {
      return renderGrayTextColumn(dateFns.format(new Date(value), 'dd/MM/yyyy'));
    },
  },
  {
    title: 'Correo',
    dataIndex: 'email',
    width: 350,
    ellipsis: true,
    render: text => <Text style={tw`font-600 text-gray-600`}>{text}</Text>,
    // sorter: (a, b) => (a.updateTime - b.updateTime > 0 ? 1 : -1),
    // render: value => {
    //   return dateFns.format(new Date(value), 'yyyy-MM-dd');
    // },
  },
];




const pageSize = 5

function EmployeesTable({onRowsSelected, queryFilter}) {
  const { pageSize, currentPage, onChange, setPage } = useTablePagination()
  const [currentFilter, setCurrentFilter] = useState("");

  const { data: employeesResult, isLoading, refetch } = useQuery({
    queryKey: ['employees.directory', { currentPage, currentFilter }],
    queryFn: getEmployeesDirectory,
  })

  useEffect(() => {
    setPage(0)
    setCurrentFilter(queryFilter)
  }, [queryFilter]);

  function getEmployeesDirectory({ queryKey }){
    console.log("getemployeesdirectroy", queryKey);

    return api.get(`/employees/directory?pagination=true`, {
      params: {
        page: queryKey[1].currentPage,
        query: queryKey[1].currentFilter,
      }
    })
      .then(({data}) => data)
  }



  // const onChange = (data = {}) => {
  //   console.log('Table changed: ', data);
  //   let { pagination, sorter, filters } = data;

  //   const dataPagination = {
  //     current_page: pagination.currentPage,
  //     page_size: pagination.pageSize,
  //   }

  //   const dataFilters = filters.map(filter => {
  //     return {
  //       index: filter.dataIndex,
  //       values: filter.filteredValue
  //     }
  //   })

  //   const dataSort = (sorter && sorter.sortOrder) ? {
  //     index: sorter.dataIndex,
  //     order: sorter.sortOrder,
  //   } : null

  //   console.log('Filters', { dataFilters, dataSort, dataPagination });
  //   setPage(pagination.currentPage)
  // }


  const rowSelection = useMemo(
    () => ({
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        onRowsSelected?.(selectedRows)
      },
      getCheckboxProps: record => ({
        disabled: record.name === 'Michael James', // Column configuration not to be checked
        name: record.name,
      }),
      fixed: true,
    }),
    []
  );

  const scroll = useMemo(() => ({ y: 500 }), []);



  return (
    <>
      <style>{".semi-table-pagination-outer{padding: 0 30px}"}</style>

      <Table
        size='small'
        columns={columns}
        dataSource={employeesResult?.data}
        rowKey={'uuid'}
        rowSelection={rowSelection}
        scroll={scroll}
        onChange={onChange}
        loading={isLoading}
        style={tw.style('bg-white rounded-lg border border-gray-100 overflow-hidden')}
        pagination={{
          currentPage,
          pageSize,
          total: employeesResult?.total,
          // showSizeChanger: true,
          // pageSizeOpts: [10, 20, 50, 200],
          // onPageChange: handlePageChange,
        }}
      />
    </>
  )
}



export default function EmployeesScreen({ navigation }) {
  const [showNewEmployeeForm, setShowNewEmployeeForm] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);

  const [showInvitationModal, setShowInvitationModal] = useState(false);
  const [queryFilter, setQueryFilter] = useState("");

  return (
    <AdminLayout title={`Colaboradores`}>

      <View style={tw`row justify-between mb-4`}>
        <View>
          <Input
            prefix={<IconSearch />}
            onEnterPress={ev => setQueryFilter(ev.currentTarget.value)}
            placeholder={"Buscar colaborador"}
            size='large'
            style={tw`bg-white`}
          />
        </View>

        {
          selectedRows.length === 0?
            <View style={tw``}>
              <Button onClick={() => setShowNewEmployeeForm(true)}>Nuevo colaborador</Button>
            </View>
          :
            <View style={tw`row bg-white rounded-xl px-8 py-2`}>
              <Button onClick={() => { }}>Asignar Jefe</Button>
              <Button onClick={() => { setShowInvitationModal(true) }}>Enviar Correo</Button>
            </View>
        }
      </View>

      <EmployeesTable queryFilter={queryFilter} onRowsSelected={setSelectedRows} />

      {
        showNewEmployeeForm &&
          <NewEmployeeForm
            onRequestClose={()=>setShowNewEmployeeForm(false)}
            onEmployeeCreated={() => {
              setShowNewEmployeeForm(false)
            }}
          />
      }

      {
        showInvitationModal &&
          <EmployeeSendInvitationModal
            onRequestClose={()=>setShowInvitationModal(false)}
            onInvitationSent={() => {
              setShowInvitationModal(false)
            }}
            selectedEmployees={selectedRows}
          />
      }
    </AdminLayout>
  );
}
