import React, {useState, useEffect, useMemo} from 'react';
import { View } from 'react-native'

import api from "../../../../utils/api"
import tw from '../../../../styles/tailwind';
import Text from '../../../../components/Text';
import Icon from '../../../../components/Icon';
import Modal from '../../../../components/Modal';

import { Form, Select, Checkbox, Radio, Button } from '@douyinfe/semi-ui'; 
import useFormSubmit from '../../../../hooks/useFormSubmit';


export default function EmployeeSendInvitationModal({ onRequestClose, onInvitationSent, selectedEmployees }) {


  const { submitForm, isSubmitting } = useFormSubmit({
    onSuccess: onInvitationSent,
    method: "post",
    onError: (error) => { },
    successToastMessage: "Invitación enviada",
    endpoint: `/employees/send-invitation`,
  })



  return (
    <Modal
      title={"Enviar correo de invitación"}
      onRequestClose={onRequestClose}
      onOk={() => submitForm({ employees: selectedEmployees.map(e => e.uuid)})}
      okText={'Enviar'}
      okButtonProps={{ loading: isSubmitting }}
    >
      <Form
        labelWidth={200}
        style={{
          padding: '10px',
        }}
        onSubmit={data => console.log("yeah", data) }
      >
        <Text>Se enviará un correo de invitación a los {selectedEmployees.length} colaboradores selecionados.</Text>

        <View style={tw`mt-4`}>
          {
            selectedEmployees.slice(0, 4).map(employee => 
              <View key={employee.uuid}>
                <Text style={tw`text-gray-600`}>{employee.full_name}</Text>  
              </View>
            )
          }

          {
            selectedEmployees.length > 5 &&
              <Text style={tw`text-gray-400`}>{selectedEmployees.length - 5} más...</Text>
          }
        </View>


      </Form>
    </Modal>
  );
}
