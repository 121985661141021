import React, {useState, useEffect, useMemo} from 'react';
import { View } from 'react-native'

import api from "../../../../utils/api"
import tw from '../../../../styles/tailwind';
import Text from '../../../../components/Text';
import Icon from '../../../../components/Icon';
import Modal from '../../../../components/Modal';

import { Form, Select, Checkbox, Radio, Button } from '@douyinfe/semi-ui'; 
import useFormSubmit from '../../../../hooks/useFormSubmit';


export default function EditPayrollInfoForm({ onRequestClose, employeeId, formData, onInfoEdited }) {
  const [formApi, setFormApi] = useState<any>(null);

  const razonesSocialesList = [
    { value: 'abc', label: 'Razón 1' },
    { value: 'hotsoon', label: 'Razón 2' },
  ];

  const tiposContratoList = [
    { value: "1", label: 'Contrato de trabajo por tiempo indeterminado' },
    { value: "2", label: ' Contrato de trabajo por obra determinada ' },
    { value: "3", label: 'Contrato de trabajo por tiempo determinado' },
    { value: "4", label: 'Contrato de trabajo por temporada' },
    { value: "5", label: 'Contrato de trabajo sujeto a prueba' },
    { value: "6", label: 'Contrato de trabajo con capacitación inicial' },
    { value: "7", label: 'Modalidad de contratación por pago de hora laborada' },
    { value: "8", label: 'Modalidad de trabajo por comisión laboral' },
    { value: "9", label: 'Modalidad de contratación donde no existe relación de trabajo' },
    { value: "10", label: 'Jubilación, pensión, retiro' },
    { value: "11", label: 'Otro contrato' },
  ];

  const { submitForm, isSubmitting } = useFormSubmit({
    onSuccess: onInfoEdited,
    method: "put",
    onError: (error) => { },
    successToastMessage: "Información guardada",
    endpoint: `/employees/${employeeId}/private-info/payroll-info`,
  })

  function onFormSubmit() {
    formApi?.validate()
      .then((values) => {
        submitForm(values)
      })
      .catch((errors) => {
        console.log("yeerrorah", errors)
      });
  }

  return (
    <Modal
      title={"Editar datos de nómina"}
      onRequestClose={onRequestClose}
      onOk={onFormSubmit}
      okText={'Guardar'}
      okButtonProps={{ loading: isSubmitting }}
    >
      <Form
        labelPosition={'left'}
        labelWidth={200}
        style={{
          paddingTop: '10px',
        }}
        getFormApi={setFormApi}
        initValues={formData}
      >
        {/* 
          x Sueldo neto
          x Sueldo bruto
          x Último ajuste de sueldo
          x Razón social
          x Tipo de contrato
          x Duración del contrato
          x Fecha de contrato
          Tipo de régimen
          x Esquema de outsorcing
         */}

        <Form.InputNumber
          field="salary_net"
          label="Salario neto"
          prefix="$"
          hideButtons
          rules={[
            // { type: 'string', message: 'Formato incorrecto', validator: (rule, value) =>  },
          ]}
          trigger='blur'
        />

        <Form.InputNumber
          field="salary_gross"
          label="Salario bruto"
          hideButtons
          prefix="$"
          rules={[
          ]}
          trigger='blur'
        />


        <Form.DatePicker
          field="salary_adjustment_date"
          insetInput
          format="dd/MM/yyyy"
          label='Último ajuste de sueldo'
          placeholder=''
        />

        <Form.Select
          label="Razón social"
          field='razon_social'
          style={{ width: 200 }}
          showClear
          optionList={razonesSocialesList}

        />

        <Form.Select
          label="Tipo de contrato"
          field='payroll_contract_type'
          style={{ width: 200 }}
          showClear
          optionList={tiposContratoList}
        />


        <Form.Input
          field="salary_contract_duration"
          label="Duración de contrato"
          rules={[
          ]}
          trigger='blur'
        />


        <Form.DatePicker
          field="contract_start_date"
          label='Fecha de contrato'
          insetInput
          format="dd/MM/yyyy"
          placeholder=''
        />

        <Form.RadioGroup
          field="salary_is_outsourcing"
          label='¿Es esquema de outsourcing?'
          >
          <Radio value={1}>Sí</Radio>
          <Radio value={0}>No</Radio>
        </Form.RadioGroup>

      </Form>
    </Modal>
  );
}
