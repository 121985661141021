import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useToast } from 'react-native-toast-notifications';
import api from '../utils/api';



type useFormSubmitProps = {
  onSuccess: (response) => void,
  onError: (error) => void,
  successToastMessage: string,
  endpoint: string,
  method?: string,
}



export default function useFormSubmit({
  onSuccess = (response) => { },
  onError = (error) => { },
  successToastMessage = "",
  method = "POST",
  endpoint = "",
} : useFormSubmitProps) {

  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast()

  function submitForm(data) {
    console.log("useFormSubmit", JSON.stringify(data));

    setIsSubmitting(true)

    // api.post(endpoint, data)
    api({
      url: endpoint,
      method: method,
      data
    })
      .then(response => {
        setIsSubmitting(false)
        
        toast.show(successToastMessage)

        onSuccess?.(response.data)
      })
      .catch(error => {
        setIsSubmitting(false)

        console.error(error.response.data);
        
        onError?.(error.response.data)
      })
  }

  return { submitForm, isSubmitting }
}
