/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../types';


// Configuración de las URL
// https://reactnavigation.org/docs/deep-linking/
// Nombres de ruta de react Navigation https://reactnavigation.org/docs/configuring-links


const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      Root: {
        screens: {
          TabOne: {
            screens: {
              TabOneScreen: 'one',
            },
          },
          TabTwo: {
            screens: {
              TabTwoScreen: 'two',
            },
          },
        },
      },
      Modal: 'modal',
      "ToS.Privacy": 'privacidad',
      "Support.Index": 'soporte',
      NotFound: '*',

      Admin: {
        screens:{
          "Home": "",
          
          "Employees.Index": "colaboradores",
          "Employees.Detail": "colaboradores/:employeeId",

          "Assistance.Index": "checador",
          "Assistance.Schedules": "checador/horarios",

          "Incidences.Index": "incidencias",
          "Settings.Index": "configuracion",
        }
      },

      "Auth.Login": "auth/login",
      "Auth.Register": "auth/complete-register",
      "Auth.Register.Confirmation": "auth/descarga-app",
    },
  },
};

export default linking;
