import * as React from 'react';
import { View } from 'react-native'

import api from "../../utils/api"
import tw from '../../styles/tailwind';
import Text from '../../components/Text';
import Icon from '../../components/Icon';
import AdminLayout from '../../navigation/AdminLayout';



export default function AdminHomeScreen({ navigation }) {

  return (
    <AdminLayout>
      <Text style={tw`mb-2 text-3xl`}>Admin dashboard</Text>


    </AdminLayout>
  );
}
