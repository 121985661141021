import React, {useState} from 'react';
import { TouchableOpacity, View } from 'react-native'

import api from "../../../utils/api"
import tw from '../../../styles/tailwind';
import Text from '../../../components/Text';
import Icon from '../../../components/Icon';
import AdminLayout from '../../../navigation/AdminLayout';
import AssistanceEmployeesSchedulesTable from './AssistanceEmployeesSchedulesTable';
import { AssistanceScreenMenu } from './AssistanceScreen';
import { useQuery } from '@tanstack/react-query';
import ScheduleFormModal from './components/ScheduleFormModal';

import { Button, Dropdown, Popconfirm, Select } from '@douyinfe/semi-ui';
import { IconMore } from '@douyinfe/semi-icons';
import { useToast } from 'react-native-toast-notifications';


function SchedulesList({ schedules, onNewSchedulePressed, onScheduleEditPressed }){
  

  return (
    <View style={tw`bg-white w-50 px-3 py-3 rounded-lg gap-y-3 pb-12`}>
      <Text style={tw`font-bold text-lg text-gray-700 mb-1`}>Horarios</Text>

      {
        schedules?.map(schedule => 
          <View key={schedule.uuid} style={tw`border border-gray-200 rounded-lg px-4 py-3 row justify-between`}>
            <Text>{schedule.name}</Text>

            <Dropdown
              render={
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => onScheduleEditPressed?.(schedule)}>Editar</Dropdown.Item>
                  <Dropdown.Item type="danger">Eliminar</Dropdown.Item>
                </Dropdown.Menu>
              }
            >
              <IconMore />
            </Dropdown>
          </View>  
        )
      }

      <TouchableOpacity onPress={onNewSchedulePressed} style={tw`row justify-center border border-primary rounded-lg py-3`}>
        <Text style={tw`font-primary text-primary font-500 mr-3`}>+</Text>
        <Text style={tw`font-primary text-primary font-500`}>Nuevo</Text>
      </TouchableOpacity>
    </View>
  )
}


function MassiveScheduleAssignBtn({employees, schedules}){
  const [selectedSchedule, setSelectedSchedule] = useState(null);

  const toast = useToast()

  const onConfirm = () => {
    return new Promise((resolve, reject) => {
      const employeeIds = employees.map(e => e.uuid)

      api.post(`/assistance/schedules/${selectedSchedule}/assign_employees`, { employees: employeeIds })
        .then(({ data }) => {
          toast.show("Horario asignado", {type: "success"})
          resolve()
        })
        .catch(({ response }) => { })
    });
  };


  return (
    <Popconfirm
      icon={null}
      title="Asignación de horarios"
      content={
        <Select
          placeholder="Selecciona un horario"
          onChange={schedule_id => setSelectedSchedule(schedule_id)}
          optionList={schedules?.map(schedule => {
            return {
              value: schedule.uuid,
              label: schedule.name,
            }
          })}
        />
      }
      onConfirm={onConfirm}
      okText={"Asignar"}
      okButtonProps={{disabled: !selectedSchedule}}
    >
      <Button disabled={employees?.length == 0}>Asignar horario</Button>
    </Popconfirm>
  )
}


export default function AssistanceSchedulesScreen({ navigation }) {
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [showNewScheduleForm, setShowNewScheduleForm] = useState(false);
  const [showScheduleEditForm, setShowScheduleEditForm] = useState(null);
  
  const { data: schedules, isLoading, refetch } = useQuery({
    queryKey: ['assistance.schedules'],
    queryFn: () => api.get(`/assistance/schedules`).then(({ data }) => data),
  })


 
  return (
    <AdminLayout title={"Asistencia"}>
      <View style={tw`row justify-between mb-4`}>
        <View></View>

        <View style={tw`row`}>
          <AssistanceScreenMenu />

          <MassiveScheduleAssignBtn schedules={schedules} employees={selectedEmployees} />
        </View>
      </View>

      

      <View style={tw`row items-start gap-x-5`}>
        <SchedulesList
          schedules={schedules}
          onNewSchedulePressed={() => setShowNewScheduleForm(true)}
          onScheduleEditPressed={(schedule) => setShowScheduleEditForm(schedule)}
        />

        <AssistanceEmployeesSchedulesTable onRowsSelected={setSelectedEmployees} />
      </View>

      {
        (showNewScheduleForm || !!showScheduleEditForm) &&
          <ScheduleFormModal
            schedule={showScheduleEditForm}
            onRequestClose={() => {
              setShowNewScheduleForm(false)
              setShowScheduleEditForm(null)
            }}
            onFormSubmitted={() => {
              setShowNewScheduleForm(false)
              setShowScheduleEditForm(null)
              refetch()
            }}
          />
      }
    </AdminLayout>
  );
}
